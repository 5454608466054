exports.components = {
  "component---cache-caches-gatsby-plugin-offline-app-shell-js": () => import("./../../caches/gatsby-plugin-offline/app-shell.js" /* webpackChunkName: "component---cache-caches-gatsby-plugin-offline-app-shell-js" */),
  "component---src-pages-404-js": () => import("./../../../src/pages/404.js" /* webpackChunkName: "component---src-pages-404-js" */),
  "component---src-pages-admin-js": () => import("./../../../src/pages/admin.js" /* webpackChunkName: "component---src-pages-admin-js" */),
  "component---src-pages-airport-taxi-transfer-solution-js": () => import("./../../../src/pages/airport-taxi-transfer-solution.js" /* webpackChunkName: "component---src-pages-airport-taxi-transfer-solution-js" */),
  "component---src-pages-alternatives-js": () => import("./../../../src/pages/alternatives.js" /* webpackChunkName: "component---src-pages-alternatives-js" */),
  "component---src-pages-beauty-cosmetic-shop-software-js": () => import("./../../../src/pages/beauty-cosmetic-shop-software.js" /* webpackChunkName: "component---src-pages-beauty-cosmetic-shop-software-js" */),
  "component---src-pages-blog-js": () => import("./../../../src/pages/blog.js" /* webpackChunkName: "component---src-pages-blog-js" */),
  "component---src-pages-boost-your-delivery-business-during-the-covid-19-js": () => import("./../../../src/pages/boost-your-delivery-business-during-the-covid-19.js" /* webpackChunkName: "component---src-pages-boost-your-delivery-business-during-the-covid-19-js" */),
  "component---src-pages-car-rental-software-js": () => import("./../../../src/pages/car-rental-software.js" /* webpackChunkName: "component---src-pages-car-rental-software-js" */),
  "component---src-pages-case-studies-js": () => import("./../../../src/pages/case-studies.js" /* webpackChunkName: "component---src-pages-case-studies-js" */),
  "component---src-pages-challenges-solutions-of-global-transportation-and-covid-19-js": () => import("./../../../src/pages/challenges-solutions-of-global-transportation-and-covid19.js" /* webpackChunkName: "component---src-pages-challenges-solutions-of-global-transportation-and-covid-19-js" */),
  "component---src-pages-compare-js": () => import("./../../../src/pages/compare.js" /* webpackChunkName: "component---src-pages-compare-js" */),
  "component---src-pages-contact-js": () => import("./../../../src/pages/contact.js" /* webpackChunkName: "component---src-pages-contact-js" */),
  "component---src-pages-corporate-taxi-booking-solution-js": () => import("./../../../src/pages/corporate-taxi-booking-solution.js" /* webpackChunkName: "component---src-pages-corporate-taxi-booking-solution-js" */),
  "component---src-pages-demo-js": () => import("./../../../src/pages/demo.js" /* webpackChunkName: "component---src-pages-demo-js" */),
  "component---src-pages-dispatcher-js": () => import("./../../../src/pages/dispatcher.js" /* webpackChunkName: "component---src-pages-dispatcher-js" */),
  "component---src-pages-driver-app-js": () => import("./../../../src/pages/driver-app.js" /* webpackChunkName: "component---src-pages-driver-app-js" */),
  "component---src-pages-drivers-subscription-js": () => import("./../../../src/pages/drivers-subscription.js" /* webpackChunkName: "component---src-pages-drivers-subscription-js" */),
  "component---src-pages-faq-js": () => import("./../../../src/pages/faq.js" /* webpackChunkName: "component---src-pages-faq-js" */),
  "component---src-pages-features-js": () => import("./../../../src/pages/features.js" /* webpackChunkName: "component---src-pages-features-js" */),
  "component---src-pages-food-delivery-app-development-js": () => import("./../../../src/pages/food-delivery-app-development.js" /* webpackChunkName: "component---src-pages-food-delivery-app-development-js" */),
  "component---src-pages-grocery-delivery-app-development-js": () => import("./../../../src/pages/grocery-delivery-app-development.js" /* webpackChunkName: "component---src-pages-grocery-delivery-app-development-js" */),
  "component---src-pages-home-kitchen-appliance-shop-management-software-js": () => import("./../../../src/pages/home-kitchen-appliance-shop-management-software.js" /* webpackChunkName: "component---src-pages-home-kitchen-appliance-shop-management-software-js" */),
  "component---src-pages-incentives-js": () => import("./../../../src/pages/incentives.js" /* webpackChunkName: "component---src-pages-incentives-js" */),
  "component---src-pages-index-js": () => import("./../../../src/pages/index.js" /* webpackChunkName: "component---src-pages-index-js" */),
  "component---src-pages-integrations-js": () => import("./../../../src/pages/integrations.js" /* webpackChunkName: "component---src-pages-integrations-js" */),
  "component---src-pages-integrations-payment-gateways-js": () => import("./../../../src/pages/integrations/payment-gateways.js" /* webpackChunkName: "component---src-pages-integrations-payment-gateways-js" */),
  "component---src-pages-limo-dispatch-booking-software-js": () => import("./../../../src/pages/limo-dispatch-booking-software.js" /* webpackChunkName: "component---src-pages-limo-dispatch-booking-software-js" */),
  "component---src-pages-manage-your-multiple-businesses-with-super-app-js": () => import("./../../../src/pages/manage-your-multiple-businesses-with-super-app.js" /* webpackChunkName: "component---src-pages-manage-your-multiple-businesses-with-super-app-js" */),
  "component---src-pages-on-demand-cargo-delivery-software-solution-js": () => import("./../../../src/pages/on-demand-cargo-delivery-software-solution.js" /* webpackChunkName: "component---src-pages-on-demand-cargo-delivery-software-solution-js" */),
  "component---src-pages-on-demand-courier-parcel-delivery-software-solution-js": () => import("./../../../src/pages/on-demand-courier-parcel-delivery-software-solution.js" /* webpackChunkName: "component---src-pages-on-demand-courier-parcel-delivery-software-solution-js" */),
  "component---src-pages-on-demand-delivery-software-for-bakeries-js": () => import("./../../../src/pages/on-demand-delivery-software-for-bakeries.js" /* webpackChunkName: "component---src-pages-on-demand-delivery-software-for-bakeries-js" */),
  "component---src-pages-on-demand-delivery-software-for-cafe-and-coffee-shops-js": () => import("./../../../src/pages/on-demand-delivery-software-for-cafe-and-coffee-shops.js" /* webpackChunkName: "component---src-pages-on-demand-delivery-software-for-cafe-and-coffee-shops-js" */),
  "component---src-pages-on-demand-delivery-software-for-cloud-kitchen-js": () => import("./../../../src/pages/on-demand-delivery-software-for-cloud-kitchen.js" /* webpackChunkName: "component---src-pages-on-demand-delivery-software-for-cloud-kitchen-js" */),
  "component---src-pages-on-demand-delivery-software-for-dark-stores-js": () => import("./../../../src/pages/on-demand-delivery-software-for-dark-stores.js" /* webpackChunkName: "component---src-pages-on-demand-delivery-software-for-dark-stores-js" */),
  "component---src-pages-on-demand-delivery-software-solution-js": () => import("./../../../src/pages/on-demand-delivery-software-solution.js" /* webpackChunkName: "component---src-pages-on-demand-delivery-software-solution-js" */),
  "component---src-pages-on-demand-food-delivery-software-solution-js": () => import("./../../../src/pages/on-demand-food-delivery-software-solution.js" /* webpackChunkName: "component---src-pages-on-demand-food-delivery-software-solution-js" */),
  "component---src-pages-on-demand-grocery-delivery-software-solution-js": () => import("./../../../src/pages/on-demand-grocery-delivery-software-solution.js" /* webpackChunkName: "component---src-pages-on-demand-grocery-delivery-software-solution-js" */),
  "component---src-pages-on-demand-materials-delivery-software-solution-js": () => import("./../../../src/pages/on-demand-materials-delivery-software-solution.js" /* webpackChunkName: "component---src-pages-on-demand-materials-delivery-software-solution-js" */),
  "component---src-pages-on-demand-meat-delivery-software-js": () => import("./../../../src/pages/on-demand-meat-delivery-software.js" /* webpackChunkName: "component---src-pages-on-demand-meat-delivery-software-js" */),
  "component---src-pages-on-demand-medicine-delivery-software-solution-js": () => import("./../../../src/pages/on-demand-medicine-delivery-software-solution.js" /* webpackChunkName: "component---src-pages-on-demand-medicine-delivery-software-solution-js" */),
  "component---src-pages-on-demand-ordering-software-solution-js": () => import("./../../../src/pages/on-demand-ordering-software-solution.js" /* webpackChunkName: "component---src-pages-on-demand-ordering-software-solution-js" */),
  "component---src-pages-online-milk-delivery-software-js": () => import("./../../../src/pages/online-milk-delivery-software.js" /* webpackChunkName: "component---src-pages-online-milk-delivery-software-js" */),
  "component---src-pages-online-ordering-and-delivery-software-js": () => import("./../../../src/pages/online-ordering-and-delivery-software.js" /* webpackChunkName: "component---src-pages-online-ordering-and-delivery-software-js" */),
  "component---src-pages-our-clients-js": () => import("./../../../src/pages/our-clients.js" /* webpackChunkName: "component---src-pages-our-clients-js" */),
  "component---src-pages-our-story-js": () => import("./../../../src/pages/our-story.js" /* webpackChunkName: "component---src-pages-our-story-js" */),
  "component---src-pages-our-usps-js": () => import("./../../../src/pages/our-usps.js" /* webpackChunkName: "component---src-pages-our-usps-js" */),
  "component---src-pages-our-vendor-js": () => import("./../../../src/pages/our-vendor.js" /* webpackChunkName: "component---src-pages-our-vendor-js" */),
  "component---src-pages-passenger-app-js": () => import("./../../../src/pages/passenger-app.js" /* webpackChunkName: "component---src-pages-passenger-app-js" */),
  "component---src-pages-pizza-ordering-delivery-platform-js": () => import("./../../../src/pages/pizza-ordering-delivery-platform.js" /* webpackChunkName: "component---src-pages-pizza-ordering-delivery-platform-js" */),
  "component---src-pages-pricing-js": () => import("./../../../src/pages/pricing.js" /* webpackChunkName: "component---src-pages-pricing-js" */),
  "component---src-pages-privacy-policy-js": () => import("./../../../src/pages/privacy-policy.js" /* webpackChunkName: "component---src-pages-privacy-policy-js" */),
  "component---src-pages-product-demo-js": () => import("./../../../src/pages/product-demo.js" /* webpackChunkName: "component---src-pages-product-demo-js" */),
  "component---src-pages-product-js": () => import("./../../../src/pages/product.js" /* webpackChunkName: "component---src-pages-product-js" */),
  "component---src-pages-reports-and-analytics-js": () => import("./../../../src/pages/reports-and-analytics.js" /* webpackChunkName: "component---src-pages-reports-and-analytics-js" */),
  "component---src-pages-resources-js": () => import("./../../../src/pages/resources.js" /* webpackChunkName: "component---src-pages-resources-js" */),
  "component---src-pages-restaurant-ordering-and-delivery-solution-js": () => import("./../../../src/pages/restaurant-ordering-and-delivery-solution.js" /* webpackChunkName: "component---src-pages-restaurant-ordering-and-delivery-solution-js" */),
  "component---src-pages-ride-hailing-software-js": () => import("./../../../src/pages/ride-hailing-software.js" /* webpackChunkName: "component---src-pages-ride-hailing-software-js" */),
  "component---src-pages-shuttle-booking-software-js": () => import("./../../../src/pages/shuttle-booking-software.js" /* webpackChunkName: "component---src-pages-shuttle-booking-software-js" */),
  "component---src-pages-smart-algorithm-js": () => import("./../../../src/pages/smart-algorithm.js" /* webpackChunkName: "component---src-pages-smart-algorithm-js" */),
  "component---src-pages-support-center-js": () => import("./../../../src/pages/support-center.js" /* webpackChunkName: "component---src-pages-support-center-js" */),
  "component---src-pages-surge-pricing-js": () => import("./../../../src/pages/surge-pricing.js" /* webpackChunkName: "component---src-pages-surge-pricing-js" */),
  "component---src-pages-taxi-app-development-solution-js": () => import("./../../../src/pages/taxi-app-development-solution.js" /* webpackChunkName: "component---src-pages-taxi-app-development-solution-js" */),
  "component---src-pages-taxi-booking-software-js": () => import("./../../../src/pages/taxi-booking-software.js" /* webpackChunkName: "component---src-pages-taxi-booking-software-js" */),
  "component---src-pages-taxi-dispatch-platform-js": () => import("./../../../src/pages/taxi-dispatch-platform.js" /* webpackChunkName: "component---src-pages-taxi-dispatch-platform-js" */),
  "component---src-pages-taxi-dispatch-software-js": () => import("./../../../src/pages/taxi-dispatch-software.js" /* webpackChunkName: "component---src-pages-taxi-dispatch-software-js" */),
  "component---src-pages-terms-of-use-js": () => import("./../../../src/pages/terms-of-use.js" /* webpackChunkName: "component---src-pages-terms-of-use-js" */),
  "component---src-pages-testimonials-js": () => import("./../../../src/pages/testimonials.js" /* webpackChunkName: "component---src-pages-testimonials-js" */),
  "component---src-pages-thankyou-js": () => import("./../../../src/pages/thankyou.js" /* webpackChunkName: "component---src-pages-thankyou-js" */),
  "component---src-pages-web-based-taxi-booking-system-js": () => import("./../../../src/pages/web-based-taxi-booking-system.js" /* webpackChunkName: "component---src-pages-web-based-taxi-booking-system-js" */),
  "component---src-pages-webinars-js": () => import("./../../../src/pages/webinars.js" /* webpackChunkName: "component---src-pages-webinars-js" */),
  "component---src-pages-whitepaper-js": () => import("./../../../src/pages/whitepaper.js" /* webpackChunkName: "component---src-pages-whitepaper-js" */),
  "component---src-pages-yelowxpress-pricing-js": () => import("./../../../src/pages/yelowxpress/pricing.js" /* webpackChunkName: "component---src-pages-yelowxpress-pricing-js" */),
  "component---src-pages-yx-admin-panel-js": () => import("./../../../src/pages/yx-admin-panel.js" /* webpackChunkName: "component---src-pages-yx-admin-panel-js" */),
  "component---src-pages-yx-customer-app-js": () => import("./../../../src/pages/yx-customer-app.js" /* webpackChunkName: "component---src-pages-yx-customer-app-js" */),
  "component---src-pages-yx-driver-app-js": () => import("./../../../src/pages/yx-driver-app.js" /* webpackChunkName: "component---src-pages-yx-driver-app-js" */),
  "component---src-pages-yx-vendor-app-js": () => import("./../../../src/pages/yx-vendor-app.js" /* webpackChunkName: "component---src-pages-yx-vendor-app-js" */),
  "component---src-pages-zone-js": () => import("./../../../src/pages/zone.js" /* webpackChunkName: "component---src-pages-zone-js" */),
  "component---src-templates-author-js": () => import("./../../../src/templates/author.js" /* webpackChunkName: "component---src-templates-author-js" */),
  "component---src-templates-blog-main-categories-js": () => import("./../../../src/templates/blog-main-categories.js" /* webpackChunkName: "component---src-templates-blog-main-categories-js" */),
  "component---src-templates-blog-single-js-content-file-path-content-blog-5-issues-fleet-management-solution-index-mdx": () => import("./../../../src/templates/blog-single.js?__contentFilePath=/opt/atlassian/pipelines/agent/build/content/blog/5-issues-fleet-management-solution/index.mdx" /* webpackChunkName: "component---src-templates-blog-single-js-content-file-path-content-blog-5-issues-fleet-management-solution-index-mdx" */),
  "component---src-templates-blog-single-js-content-file-path-content-blog-5-taxi-apps-to-make-your-morning-commute-smoother-index-mdx": () => import("./../../../src/templates/blog-single.js?__contentFilePath=/opt/atlassian/pipelines/agent/build/content/blog/5-taxi-apps-to-make-your-morning-commute-smoother/index.mdx" /* webpackChunkName: "component---src-templates-blog-single-js-content-file-path-content-blog-5-taxi-apps-to-make-your-morning-commute-smoother-index-mdx" */),
  "component---src-templates-blog-single-js-content-file-path-content-blog-7-dos-and-dont-for-successful-taxi-business-index-mdx": () => import("./../../../src/templates/blog-single.js?__contentFilePath=/opt/atlassian/pipelines/agent/build/content/blog/7-dos-and-dont-for-successful-taxi-business/index.mdx" /* webpackChunkName: "component---src-templates-blog-single-js-content-file-path-content-blog-7-dos-and-dont-for-successful-taxi-business-index-mdx" */),
  "component---src-templates-blog-single-js-content-file-path-content-blog-airport-taxi-booking-software-avoid-lost-bookings-complex-scheduling-index-mdx": () => import("./../../../src/templates/blog-single.js?__contentFilePath=/opt/atlassian/pipelines/agent/build/content/blog/airport-taxi-booking-software-avoid-lost-bookings-complex-scheduling/index.mdx" /* webpackChunkName: "component---src-templates-blog-single-js-content-file-path-content-blog-airport-taxi-booking-software-avoid-lost-bookings-complex-scheduling-index-mdx" */),
  "component---src-templates-blog-single-js-content-file-path-content-blog-an-2-cabs-a-growing-ride-hailing-company-in-india-index-mdx": () => import("./../../../src/templates/blog-single.js?__contentFilePath=/opt/atlassian/pipelines/agent/build/content/blog/an2-cabs-a-growing-ride-hailing-company-in-india/index.mdx" /* webpackChunkName: "component---src-templates-blog-single-js-content-file-path-content-blog-an-2-cabs-a-growing-ride-hailing-company-in-india-index-mdx" */),
  "component---src-templates-blog-single-js-content-file-path-content-blog-attract-more-riders-and-drivers-for-taxi-business-index-mdx": () => import("./../../../src/templates/blog-single.js?__contentFilePath=/opt/atlassian/pipelines/agent/build/content/blog/attract-more-riders-and-drivers-for-taxi-business/index.mdx" /* webpackChunkName: "component---src-templates-blog-single-js-content-file-path-content-blog-attract-more-riders-and-drivers-for-taxi-business-index-mdx" */),
  "component---src-templates-blog-single-js-content-file-path-content-blog-automate-existing-taxi-business-mobility-solution-index-mdx": () => import("./../../../src/templates/blog-single.js?__contentFilePath=/opt/atlassian/pipelines/agent/build/content/blog/automate-existing-taxi-business-mobility-solution/index.mdx" /* webpackChunkName: "component---src-templates-blog-single-js-content-file-path-content-blog-automate-existing-taxi-business-mobility-solution-index-mdx" */),
  "component---src-templates-blog-single-js-content-file-path-content-blog-automate-restaurant-business-with-tech-index-mdx": () => import("./../../../src/templates/blog-single.js?__contentFilePath=/opt/atlassian/pipelines/agent/build/content/blog/automate-restaurant-business-with-tech/index.mdx" /* webpackChunkName: "component---src-templates-blog-single-js-content-file-path-content-blog-automate-restaurant-business-with-tech-index-mdx" */),
  "component---src-templates-blog-single-js-content-file-path-content-blog-automate-your-airport-taxi-transfer-business-index-mdx": () => import("./../../../src/templates/blog-single.js?__contentFilePath=/opt/atlassian/pipelines/agent/build/content/blog/automate-your-airport-taxi-transfer-business/index.mdx" /* webpackChunkName: "component---src-templates-blog-single-js-content-file-path-content-blog-automate-your-airport-taxi-transfer-business-index-mdx" */),
  "component---src-templates-blog-single-js-content-file-path-content-blog-automated-taxi-solution-for-your-taxi-business-index-mdx": () => import("./../../../src/templates/blog-single.js?__contentFilePath=/opt/atlassian/pipelines/agent/build/content/blog/automated-taxi-solution-for-your-taxi-business/index.mdx" /* webpackChunkName: "component---src-templates-blog-single-js-content-file-path-content-blog-automated-taxi-solution-for-your-taxi-business-index-mdx" */),
  "component---src-templates-blog-single-js-content-file-path-content-blog-benefits-features-opportunities-for-corporate-taxi-booking-solution-index-mdx": () => import("./../../../src/templates/blog-single.js?__contentFilePath=/opt/atlassian/pipelines/agent/build/content/blog/benefits-features-opportunities-for-corporate-taxi-booking-solution/index.mdx" /* webpackChunkName: "component---src-templates-blog-single-js-content-file-path-content-blog-benefits-features-opportunities-for-corporate-taxi-booking-solution-index-mdx" */),
  "component---src-templates-blog-single-js-content-file-path-content-blog-benefits-of-advanced-taxi-dispatch-software-index-mdx": () => import("./../../../src/templates/blog-single.js?__contentFilePath=/opt/atlassian/pipelines/agent/build/content/blog/benefits-of-advanced-taxi-dispatch-software/index.mdx" /* webpackChunkName: "component---src-templates-blog-single-js-content-file-path-content-blog-benefits-of-advanced-taxi-dispatch-software-index-mdx" */),
  "component---src-templates-blog-single-js-content-file-path-content-blog-benefits-of-automated-grocery-delivery-business-index-mdx": () => import("./../../../src/templates/blog-single.js?__contentFilePath=/opt/atlassian/pipelines/agent/build/content/blog/benefits-of-automated-grocery-delivery-business/index.mdx" /* webpackChunkName: "component---src-templates-blog-single-js-content-file-path-content-blog-benefits-of-automated-grocery-delivery-business-index-mdx" */),
  "component---src-templates-blog-single-js-content-file-path-content-blog-benefits-of-on-board-diagnostics-index-mdx": () => import("./../../../src/templates/blog-single.js?__contentFilePath=/opt/atlassian/pipelines/agent/build/content/blog/benefits-of-on-board-diagnostics/index.mdx" /* webpackChunkName: "component---src-templates-blog-single-js-content-file-path-content-blog-benefits-of-on-board-diagnostics-index-mdx" */),
  "component---src-templates-blog-single-js-content-file-path-content-blog-benefits-of-white-label-taxi-dispatch-software-for-australian-taxi-businesses-index-mdx": () => import("./../../../src/templates/blog-single.js?__contentFilePath=/opt/atlassian/pipelines/agent/build/content/blog/benefits-of-white-label-taxi-dispatch-software-for-australian-taxi-businesses/index.mdx" /* webpackChunkName: "component---src-templates-blog-single-js-content-file-path-content-blog-benefits-of-white-label-taxi-dispatch-software-for-australian-taxi-businesses-index-mdx" */),
  "component---src-templates-blog-single-js-content-file-path-content-blog-best-icabbi-alternatives-index-mdx": () => import("./../../../src/templates/blog-single.js?__contentFilePath=/opt/atlassian/pipelines/agent/build/content/blog/best-icabbi-alternatives/index.mdx" /* webpackChunkName: "component---src-templates-blog-single-js-content-file-path-content-blog-best-icabbi-alternatives-index-mdx" */),
  "component---src-templates-blog-single-js-content-file-path-content-blog-best-ride-hailing-software-index-mdx": () => import("./../../../src/templates/blog-single.js?__contentFilePath=/opt/atlassian/pipelines/agent/build/content/blog/best-ride-hailing-software/index.mdx" /* webpackChunkName: "component---src-templates-blog-single-js-content-file-path-content-blog-best-ride-hailing-software-index-mdx" */),
  "component---src-templates-blog-single-js-content-file-path-content-blog-best-taximobility-alternatives-index-mdx": () => import("./../../../src/templates/blog-single.js?__contentFilePath=/opt/atlassian/pipelines/agent/build/content/blog/best-taximobility-alternatives/index.mdx" /* webpackChunkName: "component---src-templates-blog-single-js-content-file-path-content-blog-best-taximobility-alternatives-index-mdx" */),
  "component---src-templates-blog-single-js-content-file-path-content-blog-boost-your-grocery-business-during-covid-19-index-mdx": () => import("./../../../src/templates/blog-single.js?__contentFilePath=/opt/atlassian/pipelines/agent/build/content/blog/boost-your-grocery-business-during-covid-19/index.mdx" /* webpackChunkName: "component---src-templates-blog-single-js-content-file-path-content-blog-boost-your-grocery-business-during-covid-19-index-mdx" */),
  "component---src-templates-blog-single-js-content-file-path-content-blog-boost-your-taxi-business-with-innovation-index-mdx": () => import("./../../../src/templates/blog-single.js?__contentFilePath=/opt/atlassian/pipelines/agent/build/content/blog/boost-your-taxi-business-with-innovation/index.mdx" /* webpackChunkName: "component---src-templates-blog-single-js-content-file-path-content-blog-boost-your-taxi-business-with-innovation-index-mdx" */),
  "component---src-templates-blog-single-js-content-file-path-content-blog-boosting-restaurant-revenue-the-power-of-on-demand-delivery-software-index-mdx": () => import("./../../../src/templates/blog-single.js?__contentFilePath=/opt/atlassian/pipelines/agent/build/content/blog/boosting-restaurant-revenue-the-power-of-on-demand-delivery-software/index.mdx" /* webpackChunkName: "component---src-templates-blog-single-js-content-file-path-content-blog-boosting-restaurant-revenue-the-power-of-on-demand-delivery-software-index-mdx" */),
  "component---src-templates-blog-single-js-content-file-path-content-blog-brand-control-with-yelowxpress-customized-features-index-mdx": () => import("./../../../src/templates/blog-single.js?__contentFilePath=/opt/atlassian/pipelines/agent/build/content/blog/brand-control-with-yelowxpress-customized-features/index.mdx" /* webpackChunkName: "component---src-templates-blog-single-js-content-file-path-content-blog-brand-control-with-yelowxpress-customized-features-index-mdx" */),
  "component---src-templates-blog-single-js-content-file-path-content-blog-build-a-hyperlocal-on-demand-delivery-model-index-mdx": () => import("./../../../src/templates/blog-single.js?__contentFilePath=/opt/atlassian/pipelines/agent/build/content/blog/build-a-hyperlocal-on-demand-delivery-model/index.mdx" /* webpackChunkName: "component---src-templates-blog-single-js-content-file-path-content-blog-build-a-hyperlocal-on-demand-delivery-model-index-mdx" */),
  "component---src-templates-blog-single-js-content-file-path-content-blog-build-an-e-hailing-app-in-your-city-country-index-mdx": () => import("./../../../src/templates/blog-single.js?__contentFilePath=/opt/atlassian/pipelines/agent/build/content/blog/build-an-e-hailing-app-in-your-city-country/index.mdx" /* webpackChunkName: "component---src-templates-blog-single-js-content-file-path-content-blog-build-an-e-hailing-app-in-your-city-country-index-mdx" */),
  "component---src-templates-blog-single-js-content-file-path-content-blog-build-an-on-demand-delivery-app-like-dunzo-index-mdx": () => import("./../../../src/templates/blog-single.js?__contentFilePath=/opt/atlassian/pipelines/agent/build/content/blog/build-an-on-demand-delivery-app-like-dunzo/index.mdx" /* webpackChunkName: "component---src-templates-blog-single-js-content-file-path-content-blog-build-an-on-demand-delivery-app-like-dunzo-index-mdx" */),
  "component---src-templates-blog-single-js-content-file-path-content-blog-build-business-plan-for-taxi-companies-index-mdx": () => import("./../../../src/templates/blog-single.js?__contentFilePath=/opt/atlassian/pipelines/agent/build/content/blog/build-business-plan-for-taxi-companies/index.mdx" /* webpackChunkName: "component---src-templates-blog-single-js-content-file-path-content-blog-build-business-plan-for-taxi-companies-index-mdx" */),
  "component---src-templates-blog-single-js-content-file-path-content-blog-build-taxi-company-technology-and-mobility-index-mdx": () => import("./../../../src/templates/blog-single.js?__contentFilePath=/opt/atlassian/pipelines/agent/build/content/blog/build-taxi-company-technology-and-mobility/index.mdx" /* webpackChunkName: "component---src-templates-blog-single-js-content-file-path-content-blog-build-taxi-company-technology-and-mobility-index-mdx" */),
  "component---src-templates-blog-single-js-content-file-path-content-blog-business-recovery-plan-for-ride-hailing-companies-index-mdx": () => import("./../../../src/templates/blog-single.js?__contentFilePath=/opt/atlassian/pipelines/agent/build/content/blog/business-recovery-plan-for-ride-hailing-companies/index.mdx" /* webpackChunkName: "component---src-templates-blog-single-js-content-file-path-content-blog-business-recovery-plan-for-ride-hailing-companies-index-mdx" */),
  "component---src-templates-blog-single-js-content-file-path-content-blog-business-strategy-for-taxi-app-index-mdx": () => import("./../../../src/templates/blog-single.js?__contentFilePath=/opt/atlassian/pipelines/agent/build/content/blog/business-strategy-for-taxi-app/index.mdx" /* webpackChunkName: "component---src-templates-blog-single-js-content-file-path-content-blog-business-strategy-for-taxi-app-index-mdx" */),
  "component---src-templates-blog-single-js-content-file-path-content-blog-car-rental-software-help-to-automate-taxi-business-index-mdx": () => import("./../../../src/templates/blog-single.js?__contentFilePath=/opt/atlassian/pipelines/agent/build/content/blog/car-rental-software-help-to-automate-taxi-business/index.mdx" /* webpackChunkName: "component---src-templates-blog-single-js-content-file-path-content-blog-car-rental-software-help-to-automate-taxi-business-index-mdx" */),
  "component---src-templates-blog-single-js-content-file-path-content-blog-challengers-solutions-for-corporate-taxi-transport-service-index-mdx": () => import("./../../../src/templates/blog-single.js?__contentFilePath=/opt/atlassian/pipelines/agent/build/content/blog/challengers-solutions-for-corporate-taxi-transport-service/index.mdx" /* webpackChunkName: "component---src-templates-blog-single-js-content-file-path-content-blog-challengers-solutions-for-corporate-taxi-transport-service-index-mdx" */),
  "component---src-templates-blog-single-js-content-file-path-content-blog-challenges-faced-driver-associations-in-world-index-mdx": () => import("./../../../src/templates/blog-single.js?__contentFilePath=/opt/atlassian/pipelines/agent/build/content/blog/challenges-faced-driver-associations-in-world/index.mdx" /* webpackChunkName: "component---src-templates-blog-single-js-content-file-path-content-blog-challenges-faced-driver-associations-in-world-index-mdx" */),
  "component---src-templates-blog-single-js-content-file-path-content-blog-challenges-taxi-association-due-ride-hailing-apps-index-mdx": () => import("./../../../src/templates/blog-single.js?__contentFilePath=/opt/atlassian/pipelines/agent/build/content/blog/challenges-taxi-association-due-ride-hailing-apps/index.mdx" /* webpackChunkName: "component---src-templates-blog-single-js-content-file-path-content-blog-challenges-taxi-association-due-ride-hailing-apps-index-mdx" */),
  "component---src-templates-blog-single-js-content-file-path-content-blog-chance-for-entrepreneurs-compete-against-ola-uber-in-gujarat-index-mdx": () => import("./../../../src/templates/blog-single.js?__contentFilePath=/opt/atlassian/pipelines/agent/build/content/blog/chance-for-entrepreneurs-compete-against-ola-uber-in-gujarat/index.mdx" /* webpackChunkName: "component---src-templates-blog-single-js-content-file-path-content-blog-chance-for-entrepreneurs-compete-against-ola-uber-in-gujarat-index-mdx" */),
  "component---src-templates-blog-single-js-content-file-path-content-blog-change-your-business-with-taxi-dispatch-system-index-mdx": () => import("./../../../src/templates/blog-single.js?__contentFilePath=/opt/atlassian/pipelines/agent/build/content/blog/change-your-business-with-taxi-dispatch-system/index.mdx" /* webpackChunkName: "component---src-templates-blog-single-js-content-file-path-content-blog-change-your-business-with-taxi-dispatch-system-index-mdx" */),
  "component---src-templates-blog-single-js-content-file-path-content-blog-checkpoints-to-consider-ride-sharing-app-index-mdx": () => import("./../../../src/templates/blog-single.js?__contentFilePath=/opt/atlassian/pipelines/agent/build/content/blog/checkpoints-to-consider-ride-sharing-app/index.mdx" /* webpackChunkName: "component---src-templates-blog-single-js-content-file-path-content-blog-checkpoints-to-consider-ride-sharing-app-index-mdx" */),
  "component---src-templates-blog-single-js-content-file-path-content-blog-choosing-right-taxi-dispatch-software-index-mdx": () => import("./../../../src/templates/blog-single.js?__contentFilePath=/opt/atlassian/pipelines/agent/build/content/blog/choosing-right-taxi-dispatch-software/index.mdx" /* webpackChunkName: "component---src-templates-blog-single-js-content-file-path-content-blog-choosing-right-taxi-dispatch-software-index-mdx" */),
  "component---src-templates-blog-single-js-content-file-path-content-blog-cloud-based-mobile-first-critical-to-yelowtaxi-index-mdx": () => import("./../../../src/templates/blog-single.js?__contentFilePath=/opt/atlassian/pipelines/agent/build/content/blog/cloud-based-mobile-first-critical-to-yelowtaxi/index.mdx" /* webpackChunkName: "component---src-templates-blog-single-js-content-file-path-content-blog-cloud-based-mobile-first-critical-to-yelowtaxi-index-mdx" */),
  "component---src-templates-blog-single-js-content-file-path-content-blog-cloud-based-taxi-solution-go-without-zone-pricing-index-mdx": () => import("./../../../src/templates/blog-single.js?__contentFilePath=/opt/atlassian/pipelines/agent/build/content/blog/cloud-based-taxi-solution-go-without-zone-pricing/index.mdx" /* webpackChunkName: "component---src-templates-blog-single-js-content-file-path-content-blog-cloud-based-taxi-solution-go-without-zone-pricing-index-mdx" */),
  "component---src-templates-blog-single-js-content-file-path-content-blog-cloud-taxi-solution-vs-taxi-app-script-index-mdx": () => import("./../../../src/templates/blog-single.js?__contentFilePath=/opt/atlassian/pipelines/agent/build/content/blog/cloud-taxi-solution-vs-taxi-app-script/index.mdx" /* webpackChunkName: "component---src-templates-blog-single-js-content-file-path-content-blog-cloud-taxi-solution-vs-taxi-app-script-index-mdx" */),
  "component---src-templates-blog-single-js-content-file-path-content-blog-commuter-between-driver-and-rider-taxi-app-index-mdx": () => import("./../../../src/templates/blog-single.js?__contentFilePath=/opt/atlassian/pipelines/agent/build/content/blog/commuter-between-driver-and-rider-taxi-app/index.mdx" /* webpackChunkName: "component---src-templates-blog-single-js-content-file-path-content-blog-commuter-between-driver-and-rider-taxi-app-index-mdx" */),
  "component---src-templates-blog-single-js-content-file-path-content-blog-complete-guide-on-automated-driver-billings-payouts-index-mdx": () => import("./../../../src/templates/blog-single.js?__contentFilePath=/opt/atlassian/pipelines/agent/build/content/blog/complete-guide-on-automated-driver-billings-payouts/index.mdx" /* webpackChunkName: "component---src-templates-blog-single-js-content-file-path-content-blog-complete-guide-on-automated-driver-billings-payouts-index-mdx" */),
  "component---src-templates-blog-single-js-content-file-path-content-blog-corporate-client-management-made-effortless-yelowsofts-latest-updates-index-mdx": () => import("./../../../src/templates/blog-single.js?__contentFilePath=/opt/atlassian/pipelines/agent/build/content/blog/corporate-client-management-made-effortless-yelowsofts-latest-updates/index.mdx" /* webpackChunkName: "component---src-templates-blog-single-js-content-file-path-content-blog-corporate-client-management-made-effortless-yelowsofts-latest-updates-index-mdx" */),
  "component---src-templates-blog-single-js-content-file-path-content-blog-corporate-travel-management-software-simplifies-travel-itineraries-index-mdx": () => import("./../../../src/templates/blog-single.js?__contentFilePath=/opt/atlassian/pipelines/agent/build/content/blog/corporate-travel-management-software-simplifies-travel-itineraries/index.mdx" /* webpackChunkName: "component---src-templates-blog-single-js-content-file-path-content-blog-corporate-travel-management-software-simplifies-travel-itineraries-index-mdx" */),
  "component---src-templates-blog-single-js-content-file-path-content-blog-covid-19-impact-on-super-apps-in-south-east-asia-index-mdx": () => import("./../../../src/templates/blog-single.js?__contentFilePath=/opt/atlassian/pipelines/agent/build/content/blog/covid-19-impact-on-super-apps-in-south-east-asia/index.mdx" /* webpackChunkName: "component---src-templates-blog-single-js-content-file-path-content-blog-covid-19-impact-on-super-apps-in-south-east-asia-index-mdx" */),
  "component---src-templates-blog-single-js-content-file-path-content-blog-covid-19-on-demand-delivery-business-opportunities-index-mdx": () => import("./../../../src/templates/blog-single.js?__contentFilePath=/opt/atlassian/pipelines/agent/build/content/blog/covid-19-on-demand-delivery-business-opportunities/index.mdx" /* webpackChunkName: "component---src-templates-blog-single-js-content-file-path-content-blog-covid-19-on-demand-delivery-business-opportunities-index-mdx" */),
  "component---src-templates-blog-single-js-content-file-path-content-blog-custom-vs-readymade-restaurant-software-index-mdx": () => import("./../../../src/templates/blog-single.js?__contentFilePath=/opt/atlassian/pipelines/agent/build/content/blog/custom-vs-readymade-restaurant-software/index.mdx" /* webpackChunkName: "component---src-templates-blog-single-js-content-file-path-content-blog-custom-vs-readymade-restaurant-software-index-mdx" */),
  "component---src-templates-blog-single-js-content-file-path-content-blog-custom-vs-white-label-taxi-software-index-mdx": () => import("./../../../src/templates/blog-single.js?__contentFilePath=/opt/atlassian/pipelines/agent/build/content/blog/custom-vs-white-label-taxi-software/index.mdx" /* webpackChunkName: "component---src-templates-blog-single-js-content-file-path-content-blog-custom-vs-white-label-taxi-software-index-mdx" */),
  "component---src-templates-blog-single-js-content-file-path-content-blog-dairy-profit-minimization-milk-delivery-software-index-mdx": () => import("./../../../src/templates/blog-single.js?__contentFilePath=/opt/atlassian/pipelines/agent/build/content/blog/dairy-profit-minimization-milk-delivery-software/index.mdx" /* webpackChunkName: "component---src-templates-blog-single-js-content-file-path-content-blog-dairy-profit-minimization-milk-delivery-software-index-mdx" */),
  "component---src-templates-blog-single-js-content-file-path-content-blog-develop-an-on-demand-flower-delivery-app-index-mdx": () => import("./../../../src/templates/blog-single.js?__contentFilePath=/opt/atlassian/pipelines/agent/build/content/blog/develop-an-on-demand-flower-delivery-app/index.mdx" /* webpackChunkName: "component---src-templates-blog-single-js-content-file-path-content-blog-develop-an-on-demand-flower-delivery-app-index-mdx" */),
  "component---src-templates-blog-single-js-content-file-path-content-blog-develop-on-demand-parcel-delivery-app-like-swiggy-genie-index-mdx": () => import("./../../../src/templates/blog-single.js?__contentFilePath=/opt/atlassian/pipelines/agent/build/content/blog/develop-on-demand-parcel-delivery-app-like-swiggy-genie/index.mdx" /* webpackChunkName: "component---src-templates-blog-single-js-content-file-path-content-blog-develop-on-demand-parcel-delivery-app-like-swiggy-genie-index-mdx" */),
  "component---src-templates-blog-single-js-content-file-path-content-blog-digital-solution-to-engage-customer-for-on-demand-delivery-business-index-mdx": () => import("./../../../src/templates/blog-single.js?__contentFilePath=/opt/atlassian/pipelines/agent/build/content/blog/digital-solution-to-engage-customer-for-on-demand-delivery-business/index.mdx" /* webpackChunkName: "component---src-templates-blog-single-js-content-file-path-content-blog-digital-solution-to-engage-customer-for-on-demand-delivery-business-index-mdx" */),
  "component---src-templates-blog-single-js-content-file-path-content-blog-digitize-your-radio-taxi-business-with-taxi-dispatch-system-index-mdx": () => import("./../../../src/templates/blog-single.js?__contentFilePath=/opt/atlassian/pipelines/agent/build/content/blog/digitize-your-radio-taxi-business-with-taxi-dispatch-system/index.mdx" /* webpackChunkName: "component---src-templates-blog-single-js-content-file-path-content-blog-digitize-your-radio-taxi-business-with-taxi-dispatch-system-index-mdx" */),
  "component---src-templates-blog-single-js-content-file-path-content-blog-dispatch-software-improve-driver-efficiency-and-job-satisfaction-index-mdx": () => import("./../../../src/templates/blog-single.js?__contentFilePath=/opt/atlassian/pipelines/agent/build/content/blog/dispatch-software-improve-driver-efficiency-and-job-satisfaction/index.mdx" /* webpackChunkName: "component---src-templates-blog-single-js-content-file-path-content-blog-dispatch-software-improve-driver-efficiency-and-job-satisfaction-index-mdx" */),
  "component---src-templates-blog-single-js-content-file-path-content-blog-eaaazy-rides-ghanas-taxi-booking-startup-success-story-index-mdx": () => import("./../../../src/templates/blog-single.js?__contentFilePath=/opt/atlassian/pipelines/agent/build/content/blog/eaaazy-rides-ghanas-taxi-booking-startup-success-story/index.mdx" /* webpackChunkName: "component---src-templates-blog-single-js-content-file-path-content-blog-eaaazy-rides-ghanas-taxi-booking-startup-success-story-index-mdx" */),
  "component---src-templates-blog-single-js-content-file-path-content-blog-evolution-of-micro-mobility-index-mdx": () => import("./../../../src/templates/blog-single.js?__contentFilePath=/opt/atlassian/pipelines/agent/build/content/blog/evolution-of-micro-mobility/index.mdx" /* webpackChunkName: "component---src-templates-blog-single-js-content-file-path-content-blog-evolution-of-micro-mobility-index-mdx" */),
  "component---src-templates-blog-single-js-content-file-path-content-blog-expand-business-new-level-robust-limo-booking-software-index-mdx": () => import("./../../../src/templates/blog-single.js?__contentFilePath=/opt/atlassian/pipelines/agent/build/content/blog/expand-business-new-level-robust-limo-booking-software/index.mdx" /* webpackChunkName: "component---src-templates-blog-single-js-content-file-path-content-blog-expand-business-new-level-robust-limo-booking-software-index-mdx" */),
  "component---src-templates-blog-single-js-content-file-path-content-blog-factors-affecting-drivers-behavior-in-ride-hailing-business-index-mdx": () => import("./../../../src/templates/blog-single.js?__contentFilePath=/opt/atlassian/pipelines/agent/build/content/blog/factors-affecting-drivers-behavior-in-ride-hailing-business/index.mdx" /* webpackChunkName: "component---src-templates-blog-single-js-content-file-path-content-blog-factors-affecting-drivers-behavior-in-ride-hailing-business-index-mdx" */),
  "component---src-templates-blog-single-js-content-file-path-content-blog-food-delivery-business-need-an-online-platform-index-mdx": () => import("./../../../src/templates/blog-single.js?__contentFilePath=/opt/atlassian/pipelines/agent/build/content/blog/food-delivery-business-need-an-online-platform/index.mdx" /* webpackChunkName: "component---src-templates-blog-single-js-content-file-path-content-blog-food-delivery-business-need-an-online-platform-index-mdx" */),
  "component---src-templates-blog-single-js-content-file-path-content-blog-food-delivery-solutions-for-single-restaurant-index-mdx": () => import("./../../../src/templates/blog-single.js?__contentFilePath=/opt/atlassian/pipelines/agent/build/content/blog/food-delivery-solutions-for-single-restaurant/index.mdx" /* webpackChunkName: "component---src-templates-blog-single-js-content-file-path-content-blog-food-delivery-solutions-for-single-restaurant-index-mdx" */),
  "component---src-templates-blog-single-js-content-file-path-content-blog-free-taxi-with-an-advanced-taxi-dispatch-system-index-mdx": () => import("./../../../src/templates/blog-single.js?__contentFilePath=/opt/atlassian/pipelines/agent/build/content/blog/free-taxi-with-an-advanced-taxi-dispatch-system/index.mdx" /* webpackChunkName: "component---src-templates-blog-single-js-content-file-path-content-blog-free-taxi-with-an-advanced-taxi-dispatch-system-index-mdx" */),
  "component---src-templates-blog-single-js-content-file-path-content-blog-functionality-grocery-stores-need-to-generate-more-orders-index-mdx": () => import("./../../../src/templates/blog-single.js?__contentFilePath=/opt/atlassian/pipelines/agent/build/content/blog/functionality-grocery-stores-need-to-generate-more-orders/index.mdx" /* webpackChunkName: "component---src-templates-blog-single-js-content-file-path-content-blog-functionality-grocery-stores-need-to-generate-more-orders-index-mdx" */),
  "component---src-templates-blog-single-js-content-file-path-content-blog-geolocation-for-an-on-demand-transportation-business-index-mdx": () => import("./../../../src/templates/blog-single.js?__contentFilePath=/opt/atlassian/pipelines/agent/build/content/blog/geolocation-for-an-on-demand-transportation-business/index.mdx" /* webpackChunkName: "component---src-templates-blog-single-js-content-file-path-content-blog-geolocation-for-an-on-demand-transportation-business-index-mdx" */),
  "component---src-templates-blog-single-js-content-file-path-content-blog-go-beyond-ride-hailing-with-super-app-index-mdx": () => import("./../../../src/templates/blog-single.js?__contentFilePath=/opt/atlassian/pipelines/agent/build/content/blog/go-beyond-ride-hailing-with-super-app/index.mdx" /* webpackChunkName: "component---src-templates-blog-single-js-content-file-path-content-blog-go-beyond-ride-hailing-with-super-app-index-mdx" */),
  "component---src-templates-blog-single-js-content-file-path-content-blog-grab-defeat-uber-becomes-biggest-ride-hailing-company-in-southeast-asia-index-mdx": () => import("./../../../src/templates/blog-single.js?__contentFilePath=/opt/atlassian/pipelines/agent/build/content/blog/grab-defeat-uber-becomes-biggest-ride-hailing-company-in-southeast-asia/index.mdx" /* webpackChunkName: "component---src-templates-blog-single-js-content-file-path-content-blog-grab-defeat-uber-becomes-biggest-ride-hailing-company-in-southeast-asia-index-mdx" */),
  "component---src-templates-blog-single-js-content-file-path-content-blog-guide-google-maps-api-integration-taxi-hailing-app-index-mdx": () => import("./../../../src/templates/blog-single.js?__contentFilePath=/opt/atlassian/pipelines/agent/build/content/blog/guide-google-maps-api-integration-taxi-hailing-app/index.mdx" /* webpackChunkName: "component---src-templates-blog-single-js-content-file-path-content-blog-guide-google-maps-api-integration-taxi-hailing-app-index-mdx" */),
  "component---src-templates-blog-single-js-content-file-path-content-blog-guide-to-set-up-online-ordering-for-restaurants-index-mdx": () => import("./../../../src/templates/blog-single.js?__contentFilePath=/opt/atlassian/pipelines/agent/build/content/blog/guide-to-set-up-online-ordering-for-restaurants/index.mdx" /* webpackChunkName: "component---src-templates-blog-single-js-content-file-path-content-blog-guide-to-set-up-online-ordering-for-restaurants-index-mdx" */),
  "component---src-templates-blog-single-js-content-file-path-content-blog-how-are-taxi-booking-apps-the-smartest-solutions-to-travel-index-mdx": () => import("./../../../src/templates/blog-single.js?__contentFilePath=/opt/atlassian/pipelines/agent/build/content/blog/how-are-taxi-booking-apps-the-smartest-solutions-to-travel/index.mdx" /* webpackChunkName: "component---src-templates-blog-single-js-content-file-path-content-blog-how-are-taxi-booking-apps-the-smartest-solutions-to-travel-index-mdx" */),
  "component---src-templates-blog-single-js-content-file-path-content-blog-how-can-you-motivate-your-drivers-to-work-more-index-mdx": () => import("./../../../src/templates/blog-single.js?__contentFilePath=/opt/atlassian/pipelines/agent/build/content/blog/how-can-you-motivate-your-drivers-to-work-more/index.mdx" /* webpackChunkName: "component---src-templates-blog-single-js-content-file-path-content-blog-how-can-you-motivate-your-drivers-to-work-more-index-mdx" */),
  "component---src-templates-blog-single-js-content-file-path-content-blog-how-local-businesses-can-leverage-technology-against-aggregators-marketplace-index-mdx": () => import("./../../../src/templates/blog-single.js?__contentFilePath=/opt/atlassian/pipelines/agent/build/content/blog/how-local-businesses-can-leverage-technology-against-aggregators-marketplace/index.mdx" /* webpackChunkName: "component---src-templates-blog-single-js-content-file-path-content-blog-how-local-businesses-can-leverage-technology-against-aggregators-marketplace-index-mdx" */),
  "component---src-templates-blog-single-js-content-file-path-content-blog-how-ride-hailing-software-attracts-more-customers-index-mdx": () => import("./../../../src/templates/blog-single.js?__contentFilePath=/opt/atlassian/pipelines/agent/build/content/blog/how-ride-hailing-software-attracts-more-customers/index.mdx" /* webpackChunkName: "component---src-templates-blog-single-js-content-file-path-content-blog-how-ride-hailing-software-attracts-more-customers-index-mdx" */),
  "component---src-templates-blog-single-js-content-file-path-content-blog-how-saas-platform-streamlines-restaurant-operations-index-mdx": () => import("./../../../src/templates/blog-single.js?__contentFilePath=/opt/atlassian/pipelines/agent/build/content/blog/how-saas-platform-streamlines-restaurant-operations/index.mdx" /* webpackChunkName: "component---src-templates-blog-single-js-content-file-path-content-blog-how-saas-platform-streamlines-restaurant-operations-index-mdx" */),
  "component---src-templates-blog-single-js-content-file-path-content-blog-how-taxi-booking-app-reduces-the-operational-cost-index-mdx": () => import("./../../../src/templates/blog-single.js?__contentFilePath=/opt/atlassian/pipelines/agent/build/content/blog/how-taxi-booking-app-reduces-the-operational-cost/index.mdx" /* webpackChunkName: "component---src-templates-blog-single-js-content-file-path-content-blog-how-taxi-booking-app-reduces-the-operational-cost-index-mdx" */),
  "component---src-templates-blog-single-js-content-file-path-content-blog-how-taxi-cab-unions-beat-uber-with-uber-like-app-index-mdx": () => import("./../../../src/templates/blog-single.js?__contentFilePath=/opt/atlassian/pipelines/agent/build/content/blog/how-taxi-cab-unions-beat-uber-with-uber-like-app/index.mdx" /* webpackChunkName: "component---src-templates-blog-single-js-content-file-path-content-blog-how-taxi-cab-unions-beat-uber-with-uber-like-app-index-mdx" */),
  "component---src-templates-blog-single-js-content-file-path-content-blog-how-taxi-company-can-efficiently-manage-customer-complaitns-index-mdx": () => import("./../../../src/templates/blog-single.js?__contentFilePath=/opt/atlassian/pipelines/agent/build/content/blog/how-taxi-company-can-efficiently-manage-customer-complaitns/index.mdx" /* webpackChunkName: "component---src-templates-blog-single-js-content-file-path-content-blog-how-taxi-company-can-efficiently-manage-customer-complaitns-index-mdx" */),
  "component---src-templates-blog-single-js-content-file-path-content-blog-how-to-beat-the-competition-in-on-demand-food-delivery-business-index-mdx": () => import("./../../../src/templates/blog-single.js?__contentFilePath=/opt/atlassian/pipelines/agent/build/content/blog/how-to-beat-the-competition-in-on-demand-food-delivery-business/index.mdx" /* webpackChunkName: "component---src-templates-blog-single-js-content-file-path-content-blog-how-to-beat-the-competition-in-on-demand-food-delivery-business-index-mdx" */),
  "component---src-templates-blog-single-js-content-file-path-content-blog-how-to-boost-customer-experience-with-taxi-dispatch-software-index-mdx": () => import("./../../../src/templates/blog-single.js?__contentFilePath=/opt/atlassian/pipelines/agent/build/content/blog/how-to-boost-customer-experience-with-taxi-dispatch-software/index.mdx" /* webpackChunkName: "component---src-templates-blog-single-js-content-file-path-content-blog-how-to-boost-customer-experience-with-taxi-dispatch-software-index-mdx" */),
  "component---src-templates-blog-single-js-content-file-path-content-blog-how-to-get-more-customer-for-restaurant-index-mdx": () => import("./../../../src/templates/blog-single.js?__contentFilePath=/opt/atlassian/pipelines/agent/build/content/blog/how-to-get-more-customer-for-restaurant/index.mdx" /* webpackChunkName: "component---src-templates-blog-single-js-content-file-path-content-blog-how-to-get-more-customer-for-restaurant-index-mdx" */),
  "component---src-templates-blog-single-js-content-file-path-content-blog-how-to-implement-dynamic-pricing-strategies-with-taxi-management-software-index-mdx": () => import("./../../../src/templates/blog-single.js?__contentFilePath=/opt/atlassian/pipelines/agent/build/content/blog/how-to-implement-dynamic-pricing-strategies-with-taxi-management-software/index.mdx" /* webpackChunkName: "component---src-templates-blog-single-js-content-file-path-content-blog-how-to-implement-dynamic-pricing-strategies-with-taxi-management-software-index-mdx" */),
  "component---src-templates-blog-single-js-content-file-path-content-blog-how-to-solve-chicken-and-egg-problem-for-taxi-business-index-mdx": () => import("./../../../src/templates/blog-single.js?__contentFilePath=/opt/atlassian/pipelines/agent/build/content/blog/how-to-solve-chicken-and-egg-problem-for-taxi-business/index.mdx" /* webpackChunkName: "component---src-templates-blog-single-js-content-file-path-content-blog-how-to-solve-chicken-and-egg-problem-for-taxi-business-index-mdx" */),
  "component---src-templates-blog-single-js-content-file-path-content-blog-how-to-start-online-meat-delivery-business-index-mdx": () => import("./../../../src/templates/blog-single.js?__contentFilePath=/opt/atlassian/pipelines/agent/build/content/blog/how-to-start-online-meat-delivery-business/index.mdx" /* webpackChunkName: "component---src-templates-blog-single-js-content-file-path-content-blog-how-to-start-online-meat-delivery-business-index-mdx" */),
  "component---src-templates-blog-single-js-content-file-path-content-blog-how-to-start-taxi-private-hire-business-in-uk-index-mdx": () => import("./../../../src/templates/blog-single.js?__contentFilePath=/opt/atlassian/pipelines/agent/build/content/blog/how-to-start-taxi-private-hire-business-in-uk/index.mdx" /* webpackChunkName: "component---src-templates-blog-single-js-content-file-path-content-blog-how-to-start-taxi-private-hire-business-in-uk-index-mdx" */),
  "component---src-templates-blog-single-js-content-file-path-content-blog-how-yelowxpress-helps-restaurant-increase-recurring-customers-revenue-index-mdx": () => import("./../../../src/templates/blog-single.js?__contentFilePath=/opt/atlassian/pipelines/agent/build/content/blog/how-yelowxpress-helps-restaurant-increase-recurring-customers-revenue/index.mdx" /* webpackChunkName: "component---src-templates-blog-single-js-content-file-path-content-blog-how-yelowxpress-helps-restaurant-increase-recurring-customers-revenue-index-mdx" */),
  "component---src-templates-blog-single-js-content-file-path-content-blog-hyperlocal-delivery-business-model-complete-guide-index-mdx": () => import("./../../../src/templates/blog-single.js?__contentFilePath=/opt/atlassian/pipelines/agent/build/content/blog/hyperlocal-delivery-business-model-complete-guide/index.mdx" /* webpackChunkName: "component---src-templates-blog-single-js-content-file-path-content-blog-hyperlocal-delivery-business-model-complete-guide-index-mdx" */),
  "component---src-templates-blog-single-js-content-file-path-content-blog-impact-of-covid-19-on-ride-hailing-and-food-delivery-business-index-mdx": () => import("./../../../src/templates/blog-single.js?__contentFilePath=/opt/atlassian/pipelines/agent/build/content/blog/impact-of-covid-19-on-ride-hailing-and-food-delivery-business/index.mdx" /* webpackChunkName: "component---src-templates-blog-single-js-content-file-path-content-blog-impact-of-covid-19-on-ride-hailing-and-food-delivery-business-index-mdx" */),
  "component---src-templates-blog-single-js-content-file-path-content-blog-impact-of-mobility-as-a-service-on-transportation-index-mdx": () => import("./../../../src/templates/blog-single.js?__contentFilePath=/opt/atlassian/pipelines/agent/build/content/blog/impact-of-mobility-as-a-service-on-transportation/index.mdx" /* webpackChunkName: "component---src-templates-blog-single-js-content-file-path-content-blog-impact-of-mobility-as-a-service-on-transportation-index-mdx" */),
  "component---src-templates-blog-single-js-content-file-path-content-blog-impact-of-ride-hailing-businesses-to-public-transit-index-mdx": () => import("./../../../src/templates/blog-single.js?__contentFilePath=/opt/atlassian/pipelines/agent/build/content/blog/impact-of-ride-hailing-businesses-to-public-transit/index.mdx" /* webpackChunkName: "component---src-templates-blog-single-js-content-file-path-content-blog-impact-of-ride-hailing-businesses-to-public-transit-index-mdx" */),
  "component---src-templates-blog-single-js-content-file-path-content-blog-implement-contactless-delivery-system-for-your-delivery-business-index-mdx": () => import("./../../../src/templates/blog-single.js?__contentFilePath=/opt/atlassian/pipelines/agent/build/content/blog/implement-contactless-delivery-system-for-your-delivery-business/index.mdx" /* webpackChunkName: "component---src-templates-blog-single-js-content-file-path-content-blog-implement-contactless-delivery-system-for-your-delivery-business-index-mdx" */),
  "component---src-templates-blog-single-js-content-file-path-content-blog-importance-of-analytics-in-scaling-your-on-demand-business-index-mdx": () => import("./../../../src/templates/blog-single.js?__contentFilePath=/opt/atlassian/pipelines/agent/build/content/blog/importance-of-analytics-in-scaling-your-on-demand-business/index.mdx" /* webpackChunkName: "component---src-templates-blog-single-js-content-file-path-content-blog-importance-of-analytics-in-scaling-your-on-demand-business-index-mdx" */),
  "component---src-templates-blog-single-js-content-file-path-content-blog-importance-of-parcel-delivery-solution-index-mdx": () => import("./../../../src/templates/blog-single.js?__contentFilePath=/opt/atlassian/pipelines/agent/build/content/blog/importance-of-parcel-delivery-solution/index.mdx" /* webpackChunkName: "component---src-templates-blog-single-js-content-file-path-content-blog-importance-of-parcel-delivery-solution-index-mdx" */),
  "component---src-templates-blog-single-js-content-file-path-content-blog-important-factors-for-food-delivery-app-development-index-mdx": () => import("./../../../src/templates/blog-single.js?__contentFilePath=/opt/atlassian/pipelines/agent/build/content/blog/important-factors-for-food-delivery-app-development/index.mdx" /* webpackChunkName: "component---src-templates-blog-single-js-content-file-path-content-blog-important-factors-for-food-delivery-app-development-index-mdx" */),
  "component---src-templates-blog-single-js-content-file-path-content-blog-importants-of-app-store-optimization-for-your-taxi-booking-app-index-mdx": () => import("./../../../src/templates/blog-single.js?__contentFilePath=/opt/atlassian/pipelines/agent/build/content/blog/importants-of-app-store-optimization-for-your-taxi-booking-app/index.mdx" /* webpackChunkName: "component---src-templates-blog-single-js-content-file-path-content-blog-importants-of-app-store-optimization-for-your-taxi-booking-app-index-mdx" */),
  "component---src-templates-blog-single-js-content-file-path-content-blog-improve-food-ordering-delivery-business-index-mdx": () => import("./../../../src/templates/blog-single.js?__contentFilePath=/opt/atlassian/pipelines/agent/build/content/blog/improve-food-ordering-delivery-business/index.mdx" /* webpackChunkName: "component---src-templates-blog-single-js-content-file-path-content-blog-improve-food-ordering-delivery-business-index-mdx" */),
  "component---src-templates-blog-single-js-content-file-path-content-blog-improve-taxi-business-audience-engagement-index-mdx": () => import("./../../../src/templates/blog-single.js?__contentFilePath=/opt/atlassian/pipelines/agent/build/content/blog/improve-taxi-business-audience-engagement/index.mdx" /* webpackChunkName: "component---src-templates-blog-single-js-content-file-path-content-blog-improve-taxi-business-audience-engagement-index-mdx" */),
  "component---src-templates-blog-single-js-content-file-path-content-blog-improve-your-customer-experience-in-taxi-business-index-mdx": () => import("./../../../src/templates/blog-single.js?__contentFilePath=/opt/atlassian/pipelines/agent/build/content/blog/improve-your-customer-experience-in-taxi-business/index.mdx" /* webpackChunkName: "component---src-templates-blog-single-js-content-file-path-content-blog-improve-your-customer-experience-in-taxi-business-index-mdx" */),
  "component---src-templates-blog-single-js-content-file-path-content-blog-improve-your-passenger-satisfaction-index-mdx": () => import("./../../../src/templates/blog-single.js?__contentFilePath=/opt/atlassian/pipelines/agent/build/content/blog/improve-your-passenger-satisfaction/index.mdx" /* webpackChunkName: "component---src-templates-blog-single-js-content-file-path-content-blog-improve-your-passenger-satisfaction-index-mdx" */),
  "component---src-templates-blog-single-js-content-file-path-content-blog-improving-the-airport-transfer-experience-with-taxi-dispatch-software-index-mdx": () => import("./../../../src/templates/blog-single.js?__contentFilePath=/opt/atlassian/pipelines/agent/build/content/blog/improving-the-airport-transfer-experience-with-taxi-dispatch-software/index.mdx" /* webpackChunkName: "component---src-templates-blog-single-js-content-file-path-content-blog-improving-the-airport-transfer-experience-with-taxi-dispatch-software-index-mdx" */),
  "component---src-templates-blog-single-js-content-file-path-content-blog-inside-look-into-the-vitenam-taxi-market-index-mdx": () => import("./../../../src/templates/blog-single.js?__contentFilePath=/opt/atlassian/pipelines/agent/build/content/blog/inside-look-into-the-vitenam-taxi-market/index.mdx" /* webpackChunkName: "component---src-templates-blog-single-js-content-file-path-content-blog-inside-look-into-the-vitenam-taxi-market-index-mdx" */),
  "component---src-templates-blog-single-js-content-file-path-content-blog-key-benefits-cab-booking-systems-corporate-employees-index-mdx": () => import("./../../../src/templates/blog-single.js?__contentFilePath=/opt/atlassian/pipelines/agent/build/content/blog/key-benefits-cab-booking-systems-corporate-employees/index.mdx" /* webpackChunkName: "component---src-templates-blog-single-js-content-file-path-content-blog-key-benefits-cab-booking-systems-corporate-employees-index-mdx" */),
  "component---src-templates-blog-single-js-content-file-path-content-blog-key-benefits-of-limousine-dispatch-software-index-mdx": () => import("./../../../src/templates/blog-single.js?__contentFilePath=/opt/atlassian/pipelines/agent/build/content/blog/key-benefits-of-limousine-dispatch-software/index.mdx" /* webpackChunkName: "component---src-templates-blog-single-js-content-file-path-content-blog-key-benefits-of-limousine-dispatch-software-index-mdx" */),
  "component---src-templates-blog-single-js-content-file-path-content-blog-key-strategies-to-get-more-customers-taxi-business-index-mdx": () => import("./../../../src/templates/blog-single.js?__contentFilePath=/opt/atlassian/pipelines/agent/build/content/blog/key-strategies-to-get-more-customers-taxi-business/index.mdx" /* webpackChunkName: "component---src-templates-blog-single-js-content-file-path-content-blog-key-strategies-to-get-more-customers-taxi-business-index-mdx" */),
  "component---src-templates-blog-single-js-content-file-path-content-blog-latest-updates-on-the-ola-ban-in-bengaluru-index-mdx": () => import("./../../../src/templates/blog-single.js?__contentFilePath=/opt/atlassian/pipelines/agent/build/content/blog/latest-updates-on-the-ola-ban-in-bengaluru/index.mdx" /* webpackChunkName: "component---src-templates-blog-single-js-content-file-path-content-blog-latest-updates-on-the-ola-ban-in-bengaluru-index-mdx" */),
  "component---src-templates-blog-single-js-content-file-path-content-blog-latin-america-next-stage-for-global-ride-hailing-dominance-index-mdx": () => import("./../../../src/templates/blog-single.js?__contentFilePath=/opt/atlassian/pipelines/agent/build/content/blog/latin-america-next-stage-for-global-ride-hailing-dominance/index.mdx" /* webpackChunkName: "component---src-templates-blog-single-js-content-file-path-content-blog-latin-america-next-stage-for-global-ride-hailing-dominance-index-mdx" */),
  "component---src-templates-blog-single-js-content-file-path-content-blog-leading-ride-hailing-company-in-india-jammu-kashmir-index-mdx": () => import("./../../../src/templates/blog-single.js?__contentFilePath=/opt/atlassian/pipelines/agent/build/content/blog/leading-ride-hailing-company-in-india-jammu-kashmir/index.mdx" /* webpackChunkName: "component---src-templates-blog-single-js-content-file-path-content-blog-leading-ride-hailing-company-in-india-jammu-kashmir-index-mdx" */),
  "component---src-templates-blog-single-js-content-file-path-content-blog-level-up-dispatch-business-best-taxi-dispatch-features-index-mdx": () => import("./../../../src/templates/blog-single.js?__contentFilePath=/opt/atlassian/pipelines/agent/build/content/blog/level-up-dispatch-business-best-taxi-dispatch-features/index.mdx" /* webpackChunkName: "component---src-templates-blog-single-js-content-file-path-content-blog-level-up-dispatch-business-best-taxi-dispatch-features-index-mdx" */),
  "component---src-templates-blog-single-js-content-file-path-content-blog-limo-business-problem-solutions-index-mdx": () => import("./../../../src/templates/blog-single.js?__contentFilePath=/opt/atlassian/pipelines/agent/build/content/blog/limo-business-problem-solutions/index.mdx" /* webpackChunkName: "component---src-templates-blog-single-js-content-file-path-content-blog-limo-business-problem-solutions-index-mdx" */),
  "component---src-templates-blog-single-js-content-file-path-content-blog-major-reasons-drive-cancel-your-ride-index-mdx": () => import("./../../../src/templates/blog-single.js?__contentFilePath=/opt/atlassian/pipelines/agent/build/content/blog/major-reasons-drive-cancel-your-ride/index.mdx" /* webpackChunkName: "component---src-templates-blog-single-js-content-file-path-content-blog-major-reasons-drive-cancel-your-ride-index-mdx" */),
  "component---src-templates-blog-single-js-content-file-path-content-blog-market-scenarios-and-taxi-industry-trends-index-mdx": () => import("./../../../src/templates/blog-single.js?__contentFilePath=/opt/atlassian/pipelines/agent/build/content/blog/market-scenarios-and-taxi-industry-trends/index.mdx" /* webpackChunkName: "component---src-templates-blog-single-js-content-file-path-content-blog-market-scenarios-and-taxi-industry-trends-index-mdx" */),
  "component---src-templates-blog-single-js-content-file-path-content-blog-maximizing-taxi-business-efficiency-with-cloud-software-index-mdx": () => import("./../../../src/templates/blog-single.js?__contentFilePath=/opt/atlassian/pipelines/agent/build/content/blog/maximizing-taxi-business-efficiency-with-cloud-software/index.mdx" /* webpackChunkName: "component---src-templates-blog-single-js-content-file-path-content-blog-maximizing-taxi-business-efficiency-with-cloud-software-index-mdx" */),
  "component---src-templates-blog-single-js-content-file-path-content-blog-medicine-delivery-application-for-pharmaceutical-operations-index-mdx": () => import("./../../../src/templates/blog-single.js?__contentFilePath=/opt/atlassian/pipelines/agent/build/content/blog/medicine-delivery-application-for-pharmaceutical-operations/index.mdx" /* webpackChunkName: "component---src-templates-blog-single-js-content-file-path-content-blog-medicine-delivery-application-for-pharmaceutical-operations-index-mdx" */),
  "component---src-templates-blog-single-js-content-file-path-content-blog-mobility-revolutionize-the-transportation-industry-index-mdx": () => import("./../../../src/templates/blog-single.js?__contentFilePath=/opt/atlassian/pipelines/agent/build/content/blog/mobility-revolutionize-the-transportation-industry/index.mdx" /* webpackChunkName: "component---src-templates-blog-single-js-content-file-path-content-blog-mobility-revolutionize-the-transportation-industry-index-mdx" */),
  "component---src-templates-blog-single-js-content-file-path-content-blog-multi-store-management-solution-for-grocery-business-index-mdx": () => import("./../../../src/templates/blog-single.js?__contentFilePath=/opt/atlassian/pipelines/agent/build/content/blog/multi-store-management-solution-for-grocery-business/index.mdx" /* webpackChunkName: "component---src-templates-blog-single-js-content-file-path-content-blog-multi-store-management-solution-for-grocery-business-index-mdx" */),
  "component---src-templates-blog-single-js-content-file-path-content-blog-off-the-shelf-vs-custom-built-app-for-limo-business-index-mdx": () => import("./../../../src/templates/blog-single.js?__contentFilePath=/opt/atlassian/pipelines/agent/build/content/blog/off-the-shelf-vs-custom-built-app-for-limo-business/index.mdx" /* webpackChunkName: "component---src-templates-blog-single-js-content-file-path-content-blog-off-the-shelf-vs-custom-built-app-for-limo-business-index-mdx" */),
  "component---src-templates-blog-single-js-content-file-path-content-blog-on-demand-cargo-delivery-solution-for-freight-and-trucking-business-index-mdx": () => import("./../../../src/templates/blog-single.js?__contentFilePath=/opt/atlassian/pipelines/agent/build/content/blog/on-demand-cargo-delivery-solution-for-freight-and-trucking-business/index.mdx" /* webpackChunkName: "component---src-templates-blog-single-js-content-file-path-content-blog-on-demand-cargo-delivery-solution-for-freight-and-trucking-business-index-mdx" */),
  "component---src-templates-blog-single-js-content-file-path-content-blog-on-demand-delivery-app-to-grow-your-delivery-business-index-mdx": () => import("./../../../src/templates/blog-single.js?__contentFilePath=/opt/atlassian/pipelines/agent/build/content/blog/on-demand-delivery-app-to-grow-your-delivery-business/index.mdx" /* webpackChunkName: "component---src-templates-blog-single-js-content-file-path-content-blog-on-demand-delivery-app-to-grow-your-delivery-business-index-mdx" */),
  "component---src-templates-blog-single-js-content-file-path-content-blog-on-demand-delivery-everything-you-need-to-know-index-mdx": () => import("./../../../src/templates/blog-single.js?__contentFilePath=/opt/atlassian/pipelines/agent/build/content/blog/on-demand-delivery-everything-you-need-to-know/index.mdx" /* webpackChunkName: "component---src-templates-blog-single-js-content-file-path-content-blog-on-demand-delivery-everything-you-need-to-know-index-mdx" */),
  "component---src-templates-blog-single-js-content-file-path-content-blog-on-demand-delivery-predictions-index-mdx": () => import("./../../../src/templates/blog-single.js?__contentFilePath=/opt/atlassian/pipelines/agent/build/content/blog/on-demand-delivery-predictions/index.mdx" /* webpackChunkName: "component---src-templates-blog-single-js-content-file-path-content-blog-on-demand-delivery-predictions-index-mdx" */),
  "component---src-templates-blog-single-js-content-file-path-content-blog-on-demand-food-delivery-system-for-restaurant-business-index-mdx": () => import("./../../../src/templates/blog-single.js?__contentFilePath=/opt/atlassian/pipelines/agent/build/content/blog/on-demand-food-delivery-system-for-restaurant-business/index.mdx" /* webpackChunkName: "component---src-templates-blog-single-js-content-file-path-content-blog-on-demand-food-delivery-system-for-restaurant-business-index-mdx" */),
  "component---src-templates-blog-single-js-content-file-path-content-blog-on-demand-medicine-delivery-app-development-for-pharmacy-store-index-mdx": () => import("./../../../src/templates/blog-single.js?__contentFilePath=/opt/atlassian/pipelines/agent/build/content/blog/on-demand-medicine-delivery-app-development-for-pharmacy-store/index.mdx" /* webpackChunkName: "component---src-templates-blog-single-js-content-file-path-content-blog-on-demand-medicine-delivery-app-development-for-pharmacy-store-index-mdx" */),
  "component---src-templates-blog-single-js-content-file-path-content-blog-on-demand-medicine-delivery-solution-for-pharmacies-index-mdx": () => import("./../../../src/templates/blog-single.js?__contentFilePath=/opt/atlassian/pipelines/agent/build/content/blog/on-demand-medicine-delivery-solution-for-pharmacies/index.mdx" /* webpackChunkName: "component---src-templates-blog-single-js-content-file-path-content-blog-on-demand-medicine-delivery-solution-for-pharmacies-index-mdx" */),
  "component---src-templates-blog-single-js-content-file-path-content-blog-one-time-sale-or-pay-as-you-go-taxi-business-index-mdx": () => import("./../../../src/templates/blog-single.js?__contentFilePath=/opt/atlassian/pipelines/agent/build/content/blog/one-time-sale-or-pay-as-you-go-taxi-business/index.mdx" /* webpackChunkName: "component---src-templates-blog-single-js-content-file-path-content-blog-one-time-sale-or-pay-as-you-go-taxi-business-index-mdx" */),
  "component---src-templates-blog-single-js-content-file-path-content-blog-online-grocery-business-transformation-index-mdx": () => import("./../../../src/templates/blog-single.js?__contentFilePath=/opt/atlassian/pipelines/agent/build/content/blog/online-grocery-business-transformation/index.mdx" /* webpackChunkName: "component---src-templates-blog-single-js-content-file-path-content-blog-online-grocery-business-transformation-index-mdx" */),
  "component---src-templates-blog-single-js-content-file-path-content-blog-online-ordering-software-transforms-brick-and-mortar-business-index-mdx": () => import("./../../../src/templates/blog-single.js?__contentFilePath=/opt/atlassian/pipelines/agent/build/content/blog/online-ordering-software-transforms-brick-and-mortar-business/index.mdx" /* webpackChunkName: "component---src-templates-blog-single-js-content-file-path-content-blog-online-ordering-software-transforms-brick-and-mortar-business-index-mdx" */),
  "component---src-templates-blog-single-js-content-file-path-content-blog-partnership-of-taxi-companies-with-hotels-bars-and-restaurants-index-mdx": () => import("./../../../src/templates/blog-single.js?__contentFilePath=/opt/atlassian/pipelines/agent/build/content/blog/partnership-of-taxi-companies-with-hotels-bars-and-restaurants/index.mdx" /* webpackChunkName: "component---src-templates-blog-single-js-content-file-path-content-blog-partnership-of-taxi-companies-with-hotels-bars-and-restaurants-index-mdx" */),
  "component---src-templates-blog-single-js-content-file-path-content-blog-prescription-refills-and-delivery-with-online-medicine-delivery-platform-index-mdx": () => import("./../../../src/templates/blog-single.js?__contentFilePath=/opt/atlassian/pipelines/agent/build/content/blog/prescription-refills-and-delivery-with-online-medicine-delivery-platform/index.mdx" /* webpackChunkName: "component---src-templates-blog-single-js-content-file-path-content-blog-prescription-refills-and-delivery-with-online-medicine-delivery-platform-index-mdx" */),
  "component---src-templates-blog-single-js-content-file-path-content-blog-problems-faced-by-uber-driver-with-taxi-solution-index-mdx": () => import("./../../../src/templates/blog-single.js?__contentFilePath=/opt/atlassian/pipelines/agent/build/content/blog/problems-faced-by-uber-driver-with-taxi-solution/index.mdx" /* webpackChunkName: "component---src-templates-blog-single-js-content-file-path-content-blog-problems-faced-by-uber-driver-with-taxi-solution-index-mdx" */),
  "component---src-templates-blog-single-js-content-file-path-content-blog-profit-driven-strategies-for-traditional-taxi-dispatch-companies-index-mdx": () => import("./../../../src/templates/blog-single.js?__contentFilePath=/opt/atlassian/pipelines/agent/build/content/blog/profit-driven-strategies-for-traditional-taxi-dispatch-companies/index.mdx" /* webpackChunkName: "component---src-templates-blog-single-js-content-file-path-content-blog-profit-driven-strategies-for-traditional-taxi-dispatch-companies-index-mdx" */),
  "component---src-templates-blog-single-js-content-file-path-content-blog-questions-before-switch-taxi-booking-system-index-mdx": () => import("./../../../src/templates/blog-single.js?__contentFilePath=/opt/atlassian/pipelines/agent/build/content/blog/questions-before-switch-taxi-booking-system/index.mdx" /* webpackChunkName: "component---src-templates-blog-single-js-content-file-path-content-blog-questions-before-switch-taxi-booking-system-index-mdx" */),
  "component---src-templates-blog-single-js-content-file-path-content-blog-real-time-tracking-management-system-with-websockets-index-mdx": () => import("./../../../src/templates/blog-single.js?__contentFilePath=/opt/atlassian/pipelines/agent/build/content/blog/real-time-tracking-management-system-with-websockets/index.mdx" /* webpackChunkName: "component---src-templates-blog-single-js-content-file-path-content-blog-real-time-tracking-management-system-with-websockets-index-mdx" */),
  "component---src-templates-blog-single-js-content-file-path-content-blog-reasons-taxi-businesses-need-driver-app-index-mdx": () => import("./../../../src/templates/blog-single.js?__contentFilePath=/opt/atlassian/pipelines/agent/build/content/blog/reasons-taxi-businesses-need-driver-app/index.mdx" /* webpackChunkName: "component---src-templates-blog-single-js-content-file-path-content-blog-reasons-taxi-businesses-need-driver-app-index-mdx" */),
  "component---src-templates-blog-single-js-content-file-path-content-blog-recurring-rides-feature-by-yelowsoft-index-mdx": () => import("./../../../src/templates/blog-single.js?__contentFilePath=/opt/atlassian/pipelines/agent/build/content/blog/recurring-rides-feature-by-yelowsoft/index.mdx" /* webpackChunkName: "component---src-templates-blog-single-js-content-file-path-content-blog-recurring-rides-feature-by-yelowsoft-index-mdx" */),
  "component---src-templates-blog-single-js-content-file-path-content-blog-reduce-the-operational-costs-of-on-demand-business-with-yelowsoft-index-mdx": () => import("./../../../src/templates/blog-single.js?__contentFilePath=/opt/atlassian/pipelines/agent/build/content/blog/reduce-the-operational-costs-of-on-demand-business-with-yelowsoft/index.mdx" /* webpackChunkName: "component---src-templates-blog-single-js-content-file-path-content-blog-reduce-the-operational-costs-of-on-demand-business-with-yelowsoft-index-mdx" */),
  "component---src-templates-blog-single-js-content-file-path-content-blog-reliability-of-taxi-business-with-support-options-index-mdx": () => import("./../../../src/templates/blog-single.js?__contentFilePath=/opt/atlassian/pipelines/agent/build/content/blog/reliability-of-taxi-business-with-support-options/index.mdx" /* webpackChunkName: "component---src-templates-blog-single-js-content-file-path-content-blog-reliability-of-taxi-business-with-support-options-index-mdx" */),
  "component---src-templates-blog-single-js-content-file-path-content-blog-restaurant-pickup-delivery-best-practices-with-yelowsoft-index-mdx": () => import("./../../../src/templates/blog-single.js?__contentFilePath=/opt/atlassian/pipelines/agent/build/content/blog/restaurant-pickup-delivery-best-practices-with-yelowsoft/index.mdx" /* webpackChunkName: "component---src-templates-blog-single-js-content-file-path-content-blog-restaurant-pickup-delivery-best-practices-with-yelowsoft-index-mdx" */),
  "component---src-templates-blog-single-js-content-file-path-content-blog-revolution-of-on-demand-delivery-business-index-mdx": () => import("./../../../src/templates/blog-single.js?__contentFilePath=/opt/atlassian/pipelines/agent/build/content/blog/revolution-of-on-demand-delivery-business/index.mdx" /* webpackChunkName: "component---src-templates-blog-single-js-content-file-path-content-blog-revolution-of-on-demand-delivery-business-index-mdx" */),
  "component---src-templates-blog-single-js-content-file-path-content-blog-revolutionize-dispatch-business-with-yelowtaxi-index-mdx": () => import("./../../../src/templates/blog-single.js?__contentFilePath=/opt/atlassian/pipelines/agent/build/content/blog/revolutionize-dispatch-business-with-yelowtaxi/index.mdx" /* webpackChunkName: "component---src-templates-blog-single-js-content-file-path-content-blog-revolutionize-dispatch-business-with-yelowtaxi-index-mdx" */),
  "component---src-templates-blog-single-js-content-file-path-content-blog-ride-hailing-a-glimpse-into-the-future-index-mdx": () => import("./../../../src/templates/blog-single.js?__contentFilePath=/opt/atlassian/pipelines/agent/build/content/blog/ride-hailing-a-glimpse-into-the-future/index.mdx" /* webpackChunkName: "component---src-templates-blog-single-js-content-file-path-content-blog-ride-hailing-a-glimpse-into-the-future-index-mdx" */),
  "component---src-templates-blog-single-js-content-file-path-content-blog-ride-hailing-industry-shaping-up-in-north-africa-index-mdx": () => import("./../../../src/templates/blog-single.js?__contentFilePath=/opt/atlassian/pipelines/agent/build/content/blog/ride-hailing-industry-shaping-up-in-north-africa/index.mdx" /* webpackChunkName: "component---src-templates-blog-single-js-content-file-path-content-blog-ride-hailing-industry-shaping-up-in-north-africa-index-mdx" */),
  "component---src-templates-blog-single-js-content-file-path-content-blog-ride-hailing-landscape-caribbean-islands-ground-reality-market-scenario-major-players-index-mdx": () => import("./../../../src/templates/blog-single.js?__contentFilePath=/opt/atlassian/pipelines/agent/build/content/blog/ride-hailing-landscape-caribbean-islands-ground-reality-market-scenario-major-players/index.mdx" /* webpackChunkName: "component---src-templates-blog-single-js-content-file-path-content-blog-ride-hailing-landscape-caribbean-islands-ground-reality-market-scenario-major-players-index-mdx" */),
  "component---src-templates-blog-single-js-content-file-path-content-blog-ride-hailing-landscape-of-oceania-index-mdx": () => import("./../../../src/templates/blog-single.js?__contentFilePath=/opt/atlassian/pipelines/agent/build/content/blog/ride-hailing-landscape-of-oceania/index.mdx" /* webpackChunkName: "component---src-templates-blog-single-js-content-file-path-content-blog-ride-hailing-landscape-of-oceania-index-mdx" */),
  "component---src-templates-blog-single-js-content-file-path-content-blog-ride-hailing-landscape-of-western-and-central-europe-index-mdx": () => import("./../../../src/templates/blog-single.js?__contentFilePath=/opt/atlassian/pipelines/agent/build/content/blog/ride-hailing-landscape-of-western-and-central-europe/index.mdx" /* webpackChunkName: "component---src-templates-blog-single-js-content-file-path-content-blog-ride-hailing-landscape-of-western-and-central-europe-index-mdx" */),
  "component---src-templates-blog-single-js-content-file-path-content-blog-ride-hailing-market-in-middle-east-index-mdx": () => import("./../../../src/templates/blog-single.js?__contentFilePath=/opt/atlassian/pipelines/agent/build/content/blog/ride-hailing-market-in-middle-east/index.mdx" /* webpackChunkName: "component---src-templates-blog-single-js-content-file-path-content-blog-ride-hailing-market-in-middle-east-index-mdx" */),
  "component---src-templates-blog-single-js-content-file-path-content-blog-ride-hailing-market-in-west-africa-index-mdx": () => import("./../../../src/templates/blog-single.js?__contentFilePath=/opt/atlassian/pipelines/agent/build/content/blog/ride-hailing-market-in-west-africa/index.mdx" /* webpackChunkName: "component---src-templates-blog-single-js-content-file-path-content-blog-ride-hailing-market-in-west-africa-index-mdx" */),
  "component---src-templates-blog-single-js-content-file-path-content-blog-rise-of-electric-vehicles-in-delivery-businesses-index-mdx": () => import("./../../../src/templates/blog-single.js?__contentFilePath=/opt/atlassian/pipelines/agent/build/content/blog/rise-of-electric-vehicles-in-delivery-businesses/index.mdx" /* webpackChunkName: "component---src-templates-blog-single-js-content-file-path-content-blog-rise-of-electric-vehicles-in-delivery-businesses-index-mdx" */),
  "component---src-templates-blog-single-js-content-file-path-content-blog-role-of-gps-tracking-taxi-business-index-mdx": () => import("./../../../src/templates/blog-single.js?__contentFilePath=/opt/atlassian/pipelines/agent/build/content/blog/role-of-gps-tracking-taxi-business/index.mdx" /* webpackChunkName: "component---src-templates-blog-single-js-content-file-path-content-blog-role-of-gps-tracking-taxi-business-index-mdx" */),
  "component---src-templates-blog-single-js-content-file-path-content-blog-role-of-in-app-wallet-in-ride-hailing-index-mdx": () => import("./../../../src/templates/blog-single.js?__contentFilePath=/opt/atlassian/pipelines/agent/build/content/blog/role-of-in-app-wallet-in-ride-hailing/index.mdx" /* webpackChunkName: "component---src-templates-blog-single-js-content-file-path-content-blog-role-of-in-app-wallet-in-ride-hailing-index-mdx" */),
  "component---src-templates-blog-single-js-content-file-path-content-blog-role-of-mobile-app-in-taxi-management-features-benefits-index-mdx": () => import("./../../../src/templates/blog-single.js?__contentFilePath=/opt/atlassian/pipelines/agent/build/content/blog/role-of-mobile-app-in-taxi-management-features-benefits/index.mdx" /* webpackChunkName: "component---src-templates-blog-single-js-content-file-path-content-blog-role-of-mobile-app-in-taxi-management-features-benefits-index-mdx" */),
  "component---src-templates-blog-single-js-content-file-path-content-blog-saas-based-solution-for-ride-hailing-startup-index-mdx": () => import("./../../../src/templates/blog-single.js?__contentFilePath=/opt/atlassian/pipelines/agent/build/content/blog/saas-based-solution-for-ride-hailing-startup/index.mdx" /* webpackChunkName: "component---src-templates-blog-single-js-content-file-path-content-blog-saas-based-solution-for-ride-hailing-startup-index-mdx" */),
  "component---src-templates-blog-single-js-content-file-path-content-blog-saas-business-model-for-on-demand-startup-index-mdx": () => import("./../../../src/templates/blog-single.js?__contentFilePath=/opt/atlassian/pipelines/agent/build/content/blog/saas-business-model-for-on-demand-startup/index.mdx" /* webpackChunkName: "component---src-templates-blog-single-js-content-file-path-content-blog-saas-business-model-for-on-demand-startup-index-mdx" */),
  "component---src-templates-blog-single-js-content-file-path-content-blog-saas-vs-in-house-vs-clone-softwares-for-on-demand-business-index-mdx": () => import("./../../../src/templates/blog-single.js?__contentFilePath=/opt/atlassian/pipelines/agent/build/content/blog/saas-vs-in-house-vs-clone-softwares-for-on-demand-business/index.mdx" /* webpackChunkName: "component---src-templates-blog-single-js-content-file-path-content-blog-saas-vs-in-house-vs-clone-softwares-for-on-demand-business-index-mdx" */),
  "component---src-templates-blog-single-js-content-file-path-content-blog-save-time-and-money-using-taxi-dispatch-software-index-mdx": () => import("./../../../src/templates/blog-single.js?__contentFilePath=/opt/atlassian/pipelines/agent/build/content/blog/save-time-and-money-using-taxi-dispatch-software/index.mdx" /* webpackChunkName: "component---src-templates-blog-single-js-content-file-path-content-blog-save-time-and-money-using-taxi-dispatch-software-index-mdx" */),
  "component---src-templates-blog-single-js-content-file-path-content-blog-self-operating-kiosks-system-for-taxi-business-index-mdx": () => import("./../../../src/templates/blog-single.js?__contentFilePath=/opt/atlassian/pipelines/agent/build/content/blog/self-operating-kiosks-system-for-taxi-business/index.mdx" /* webpackChunkName: "component---src-templates-blog-single-js-content-file-path-content-blog-self-operating-kiosks-system-for-taxi-business-index-mdx" */),
  "component---src-templates-blog-single-js-content-file-path-content-blog-smes-gains-benefits-by-switching-to-electric-vehicle-index-mdx": () => import("./../../../src/templates/blog-single.js?__contentFilePath=/opt/atlassian/pipelines/agent/build/content/blog/smes-gains-benefits-by-switching-to-electric-vehicle/index.mdx" /* webpackChunkName: "component---src-templates-blog-single-js-content-file-path-content-blog-smes-gains-benefits-by-switching-to-electric-vehicle-index-mdx" */),
  "component---src-templates-blog-single-js-content-file-path-content-blog-software-based-dispatch-is-critical-for-taxi-businesses-index-mdx": () => import("./../../../src/templates/blog-single.js?__contentFilePath=/opt/atlassian/pipelines/agent/build/content/blog/software-based-dispatch-is-critical-for-taxi-businesses/index.mdx" /* webpackChunkName: "component---src-templates-blog-single-js-content-file-path-content-blog-software-based-dispatch-is-critical-for-taxi-businesses-index-mdx" */),
  "component---src-templates-blog-single-js-content-file-path-content-blog-spesho-expands-taxi-business-with-yelowsoft-corporate-taxi-booking-solution-index-mdx": () => import("./../../../src/templates/blog-single.js?__contentFilePath=/opt/atlassian/pipelines/agent/build/content/blog/spesho-expands-taxi-business-with-yelowsoft-corporate-taxi-booking-solution/index.mdx" /* webpackChunkName: "component---src-templates-blog-single-js-content-file-path-content-blog-spesho-expands-taxi-business-with-yelowsoft-corporate-taxi-booking-solution-index-mdx" */),
  "component---src-templates-blog-single-js-content-file-path-content-blog-streamline-your-limo-business-with-mobile-first-solution-index-mdx": () => import("./../../../src/templates/blog-single.js?__contentFilePath=/opt/atlassian/pipelines/agent/build/content/blog/streamline-your-limo-business-with-mobile-first-solution/index.mdx" /* webpackChunkName: "component---src-templates-blog-single-js-content-file-path-content-blog-streamline-your-limo-business-with-mobile-first-solution-index-mdx" */),
  "component---src-templates-blog-single-js-content-file-path-content-blog-streamline-your-taxi-business-with-best-software-index-mdx": () => import("./../../../src/templates/blog-single.js?__contentFilePath=/opt/atlassian/pipelines/agent/build/content/blog/streamline-your-taxi-business-with-best-software/index.mdx" /* webpackChunkName: "component---src-templates-blog-single-js-content-file-path-content-blog-streamline-your-taxi-business-with-best-software-index-mdx" */),
  "component---src-templates-blog-single-js-content-file-path-content-blog-taxi-business-on-taxi-booking-app-scripts-index-mdx": () => import("./../../../src/templates/blog-single.js?__contentFilePath=/opt/atlassian/pipelines/agent/build/content/blog/taxi-business-on-taxi-booking-app-scripts/index.mdx" /* webpackChunkName: "component---src-templates-blog-single-js-content-file-path-content-blog-taxi-business-on-taxi-booking-app-scripts-index-mdx" */),
  "component---src-templates-blog-single-js-content-file-path-content-blog-taxi-business-with-app-like-uber-index-mdx": () => import("./../../../src/templates/blog-single.js?__contentFilePath=/opt/atlassian/pipelines/agent/build/content/blog/taxi-business-with-app-like-uber/index.mdx" /* webpackChunkName: "component---src-templates-blog-single-js-content-file-path-content-blog-taxi-business-with-app-like-uber-index-mdx" */),
  "component---src-templates-blog-single-js-content-file-path-content-blog-taxi-dispatch-software-to-managing-corporate-clients-index-mdx": () => import("./../../../src/templates/blog-single.js?__contentFilePath=/opt/atlassian/pipelines/agent/build/content/blog/taxi-dispatch-software-to-managing-corporate-clients/index.mdx" /* webpackChunkName: "component---src-templates-blog-single-js-content-file-path-content-blog-taxi-dispatch-software-to-managing-corporate-clients-index-mdx" */),
  "component---src-templates-blog-single-js-content-file-path-content-blog-taxi-dispatch-system-for-traditional-taxi-services-index-mdx": () => import("./../../../src/templates/blog-single.js?__contentFilePath=/opt/atlassian/pipelines/agent/build/content/blog/taxi-dispatch-system-for-traditional-taxi-services/index.mdx" /* webpackChunkName: "component---src-templates-blog-single-js-content-file-path-content-blog-taxi-dispatch-system-for-traditional-taxi-services-index-mdx" */),
  "component---src-templates-blog-single-js-content-file-path-content-blog-taxi-hailing-applications-revolutionized-taxi-industry-index-mdx": () => import("./../../../src/templates/blog-single.js?__contentFilePath=/opt/atlassian/pipelines/agent/build/content/blog/taxi-hailing-applications-revolutionized-taxi-industry/index.mdx" /* webpackChunkName: "component---src-templates-blog-single-js-content-file-path-content-blog-taxi-hailing-applications-revolutionized-taxi-industry-index-mdx" */),
  "component---src-templates-blog-single-js-content-file-path-content-blog-taxi-owners-can-change-mind-set-changing-taxi-dispatch-system-index-mdx": () => import("./../../../src/templates/blog-single.js?__contentFilePath=/opt/atlassian/pipelines/agent/build/content/blog/taxi-owners-can-change-mind-set-changing-taxi-dispatch-system/index.mdx" /* webpackChunkName: "component---src-templates-blog-single-js-content-file-path-content-blog-taxi-owners-can-change-mind-set-changing-taxi-dispatch-system-index-mdx" */),
  "component---src-templates-blog-single-js-content-file-path-content-blog-taxi-start-ups-should-hire-yelowsoft-instead-of-custom-solution-index-mdx": () => import("./../../../src/templates/blog-single.js?__contentFilePath=/opt/atlassian/pipelines/agent/build/content/blog/taxi-start-ups-should-hire-yelowsoft-instead-of-custom-solution/index.mdx" /* webpackChunkName: "component---src-templates-blog-single-js-content-file-path-content-blog-taxi-start-ups-should-hire-yelowsoft-instead-of-custom-solution-index-mdx" */),
  "component---src-templates-blog-single-js-content-file-path-content-blog-taxi-strike-spain-sign-of-taxi-revolution-index-mdx": () => import("./../../../src/templates/blog-single.js?__contentFilePath=/opt/atlassian/pipelines/agent/build/content/blog/taxi-strike-spain-sign-of-taxi-revolution/index.mdx" /* webpackChunkName: "component---src-templates-blog-single-js-content-file-path-content-blog-taxi-strike-spain-sign-of-taxi-revolution-index-mdx" */),
  "component---src-templates-blog-single-js-content-file-path-content-blog-ted-talks-hints-at-the-future-of-taxi-companies-index-mdx": () => import("./../../../src/templates/blog-single.js?__contentFilePath=/opt/atlassian/pipelines/agent/build/content/blog/ted-talks-hints-at-the-future-of-taxi-companies/index.mdx" /* webpackChunkName: "component---src-templates-blog-single-js-content-file-path-content-blog-ted-talks-hints-at-the-future-of-taxi-companies-index-mdx" */),
  "component---src-templates-blog-single-js-content-file-path-content-blog-the-most-comprehensive-mobility-solution-for-a-taxi-business-index-mdx": () => import("./../../../src/templates/blog-single.js?__contentFilePath=/opt/atlassian/pipelines/agent/build/content/blog/the-most-comprehensive-mobility-solution-for-a-taxi-business/index.mdx" /* webpackChunkName: "component---src-templates-blog-single-js-content-file-path-content-blog-the-most-comprehensive-mobility-solution-for-a-taxi-business-index-mdx" */),
  "component---src-templates-blog-single-js-content-file-path-content-blog-the-secret-sauce-restaurant-profit-boost-index-mdx": () => import("./../../../src/templates/blog-single.js?__contentFilePath=/opt/atlassian/pipelines/agent/build/content/blog/the-secret-sauce-restaurant-profit-boost/index.mdx" /* webpackChunkName: "component---src-templates-blog-single-js-content-file-path-content-blog-the-secret-sauce-restaurant-profit-boost-index-mdx" */),
  "component---src-templates-blog-single-js-content-file-path-content-blog-things-required-start-taxi-business-small-town-south-africa-index-mdx": () => import("./../../../src/templates/blog-single.js?__contentFilePath=/opt/atlassian/pipelines/agent/build/content/blog/things-required-start-taxi-business-small-town-south-africa/index.mdx" /* webpackChunkName: "component---src-templates-blog-single-js-content-file-path-content-blog-things-required-start-taxi-business-small-town-south-africa-index-mdx" */),
  "component---src-templates-blog-single-js-content-file-path-content-blog-things-to-consider-before-developing-an-on-demand-food-delivery-app-index-mdx": () => import("./../../../src/templates/blog-single.js?__contentFilePath=/opt/atlassian/pipelines/agent/build/content/blog/things-to-consider-before-developing-an-on-demand-food-delivery-app/index.mdx" /* webpackChunkName: "component---src-templates-blog-single-js-content-file-path-content-blog-things-to-consider-before-developing-an-on-demand-food-delivery-app-index-mdx" */),
  "component---src-templates-blog-single-js-content-file-path-content-blog-through-on-demand-parcel-delivery-digital-transformation-index-mdx": () => import("./../../../src/templates/blog-single.js?__contentFilePath=/opt/atlassian/pipelines/agent/build/content/blog/through-on-demand-parcel-delivery-digital-transformation/index.mdx" /* webpackChunkName: "component---src-templates-blog-single-js-content-file-path-content-blog-through-on-demand-parcel-delivery-digital-transformation-index-mdx" */),
  "component---src-templates-blog-single-js-content-file-path-content-blog-tips-strategies-can-follow-compete-uber-lyft-ola-index-mdx": () => import("./../../../src/templates/blog-single.js?__contentFilePath=/opt/atlassian/pipelines/agent/build/content/blog/tips-strategies-can-follow-compete-uber-lyft-ola/index.mdx" /* webpackChunkName: "component---src-templates-blog-single-js-content-file-path-content-blog-tips-strategies-can-follow-compete-uber-lyft-ola-index-mdx" */),
  "component---src-templates-blog-single-js-content-file-path-content-blog-top-5-challenges-faced-by-conventional-limousine-companies-index-mdx": () => import("./../../../src/templates/blog-single.js?__contentFilePath=/opt/atlassian/pipelines/agent/build/content/blog/top-5-challenges-faced-by-conventional-limousine-companies/index.mdx" /* webpackChunkName: "component---src-templates-blog-single-js-content-file-path-content-blog-top-5-challenges-faced-by-conventional-limousine-companies-index-mdx" */),
  "component---src-templates-blog-single-js-content-file-path-content-blog-top-6-reasons-why-school-bus-tracking-system-is-a-must-have-for-academic-institutes-index-mdx": () => import("./../../../src/templates/blog-single.js?__contentFilePath=/opt/atlassian/pipelines/agent/build/content/blog/top-6-reasons-why-school-bus-tracking-system-is-a-must-have-for-academic-institutes/index.mdx" /* webpackChunkName: "component---src-templates-blog-single-js-content-file-path-content-blog-top-6-reasons-why-school-bus-tracking-system-is-a-must-have-for-academic-institutes-index-mdx" */),
  "component---src-templates-blog-single-js-content-file-path-content-blog-top-features-for-restaurant-delivery-software-index-mdx": () => import("./../../../src/templates/blog-single.js?__contentFilePath=/opt/atlassian/pipelines/agent/build/content/blog/top-features-for-restaurant-delivery-software/index.mdx" /* webpackChunkName: "component---src-templates-blog-single-js-content-file-path-content-blog-top-features-for-restaurant-delivery-software-index-mdx" */),
  "component---src-templates-blog-single-js-content-file-path-content-blog-top-lessons-to-learn-from-failed-taxi-booking-startup-index-mdx": () => import("./../../../src/templates/blog-single.js?__contentFilePath=/opt/atlassian/pipelines/agent/build/content/blog/top-lessons-to-learn-from-failed-taxi-booking-startup/index.mdx" /* webpackChunkName: "component---src-templates-blog-single-js-content-file-path-content-blog-top-lessons-to-learn-from-failed-taxi-booking-startup-index-mdx" */),
  "component---src-templates-blog-single-js-content-file-path-content-blog-top-promotion-retention-strategies-taxi-business-shouldnt-miss-index-mdx": () => import("./../../../src/templates/blog-single.js?__contentFilePath=/opt/atlassian/pipelines/agent/build/content/blog/top-promotion-retention-strategies-taxi-business-shouldnt-miss/index.mdx" /* webpackChunkName: "component---src-templates-blog-single-js-content-file-path-content-blog-top-promotion-retention-strategies-taxi-business-shouldnt-miss-index-mdx" */),
  "component---src-templates-blog-single-js-content-file-path-content-blog-top-taxi-dispatch-software-solutions-index-mdx": () => import("./../../../src/templates/blog-single.js?__contentFilePath=/opt/atlassian/pipelines/agent/build/content/blog/top-taxi-dispatch-software-solutions/index.mdx" /* webpackChunkName: "component---src-templates-blog-single-js-content-file-path-content-blog-top-taxi-dispatch-software-solutions-index-mdx" */),
  "component---src-templates-blog-single-js-content-file-path-content-blog-uber-acquires-autocab-to-extend-reach-in-uk-index-mdx": () => import("./../../../src/templates/blog-single.js?__contentFilePath=/opt/atlassian/pipelines/agent/build/content/blog/uber-acquires-autocab-to-extend-reach-in-uk/index.mdx" /* webpackChunkName: "component---src-templates-blog-single-js-content-file-path-content-blog-uber-acquires-autocab-to-extend-reach-in-uk-index-mdx" */),
  "component---src-templates-blog-single-js-content-file-path-content-blog-uber-advertising-platform-benefits-on-market-index-mdx": () => import("./../../../src/templates/blog-single.js?__contentFilePath=/opt/atlassian/pipelines/agent/build/content/blog/uber-advertising-platform-benefits-on-market/index.mdx" /* webpackChunkName: "component---src-templates-blog-single-js-content-file-path-content-blog-uber-advertising-platform-benefits-on-market-index-mdx" */),
  "component---src-templates-blog-single-js-content-file-path-content-blog-uber-for-x-scams-index-mdx": () => import("./../../../src/templates/blog-single.js?__contentFilePath=/opt/atlassian/pipelines/agent/build/content/blog/uber-for-x-scams/index.mdx" /* webpackChunkName: "component---src-templates-blog-single-js-content-file-path-content-blog-uber-for-x-scams-index-mdx" */),
  "component---src-templates-blog-single-js-content-file-path-content-blog-ubers-acquisition-of-careem-index-mdx": () => import("./../../../src/templates/blog-single.js?__contentFilePath=/opt/atlassian/pipelines/agent/build/content/blog/ubers-acquisition-of-careem/index.mdx" /* webpackChunkName: "component---src-templates-blog-single-js-content-file-path-content-blog-ubers-acquisition-of-careem-index-mdx" */),
  "component---src-templates-blog-single-js-content-file-path-content-blog-we-help-nalataxi-to-automate-their-taxi-business-in-maldives-index-mdx": () => import("./../../../src/templates/blog-single.js?__contentFilePath=/opt/atlassian/pipelines/agent/build/content/blog/we-help-nalataxi-to-automate-their-taxi-business-in-maldives/index.mdx" /* webpackChunkName: "component---src-templates-blog-single-js-content-file-path-content-blog-we-help-nalataxi-to-automate-their-taxi-business-in-maldives-index-mdx" */),
  "component---src-templates-blog-single-js-content-file-path-content-blog-we-helped-sk-taxi-to-automate-there-on-demand-business-index-mdx": () => import("./../../../src/templates/blog-single.js?__contentFilePath=/opt/atlassian/pipelines/agent/build/content/blog/we-helped-sk-taxi-to-automate-there-on-demand-business/index.mdx" /* webpackChunkName: "component---src-templates-blog-single-js-content-file-path-content-blog-we-helped-sk-taxi-to-automate-there-on-demand-business-index-mdx" */),
  "component---src-templates-blog-single-js-content-file-path-content-blog-we-helped-twenshe-to-automate-there-on-demand-business-index-mdx": () => import("./../../../src/templates/blog-single.js?__contentFilePath=/opt/atlassian/pipelines/agent/build/content/blog/we-helped-twenshe-to-automate-there-on-demand-business/index.mdx" /* webpackChunkName: "component---src-templates-blog-single-js-content-file-path-content-blog-we-helped-twenshe-to-automate-there-on-demand-business-index-mdx" */),
  "component---src-templates-blog-single-js-content-file-path-content-blog-website-or-mobile-app-for-taxi-businesses-index-mdx": () => import("./../../../src/templates/blog-single.js?__contentFilePath=/opt/atlassian/pipelines/agent/build/content/blog/website-or-mobile-app-for-taxi-businesses/index.mdx" /* webpackChunkName: "component---src-templates-blog-single-js-content-file-path-content-blog-website-or-mobile-app-for-taxi-businesses-index-mdx" */),
  "component---src-templates-blog-single-js-content-file-path-content-blog-what-makes-the-taxi-business-to-invest-in-a-taxi-mobility-solution-index-mdx": () => import("./../../../src/templates/blog-single.js?__contentFilePath=/opt/atlassian/pipelines/agent/build/content/blog/what-makes-the-taxi-business-to-invest-in-a-taxi-mobility-solution/index.mdx" /* webpackChunkName: "component---src-templates-blog-single-js-content-file-path-content-blog-what-makes-the-taxi-business-to-invest-in-a-taxi-mobility-solution-index-mdx" */),
  "component---src-templates-blog-single-js-content-file-path-content-blog-what-transportation-taxi-industry-have-in-sleeves-for-2018-index-mdx": () => import("./../../../src/templates/blog-single.js?__contentFilePath=/opt/atlassian/pipelines/agent/build/content/blog/what-transportation-taxi-industry-have-in-sleeves-for-2018/index.mdx" /* webpackChunkName: "component---src-templates-blog-single-js-content-file-path-content-blog-what-transportation-taxi-industry-have-in-sleeves-for-2018-index-mdx" */),
  "component---src-templates-blog-single-js-content-file-path-content-blog-white-label-rideshare-app-development-guide-index-mdx": () => import("./../../../src/templates/blog-single.js?__contentFilePath=/opt/atlassian/pipelines/agent/build/content/blog/white-label-rideshare-app-development-guide/index.mdx" /* webpackChunkName: "component---src-templates-blog-single-js-content-file-path-content-blog-white-label-rideshare-app-development-guide-index-mdx" */),
  "component---src-templates-blog-single-js-content-file-path-content-blog-white-label-saas-software-best-way-to-own-brand-online-index-mdx": () => import("./../../../src/templates/blog-single.js?__contentFilePath=/opt/atlassian/pipelines/agent/build/content/blog/white-label-saas-software-best-way-to-own-brand-online/index.mdx" /* webpackChunkName: "component---src-templates-blog-single-js-content-file-path-content-blog-white-label-saas-software-best-way-to-own-brand-online-index-mdx" */),
  "component---src-templates-blog-single-js-content-file-path-content-blog-white-label-solution-for-food-delivery-app-index-mdx": () => import("./../../../src/templates/blog-single.js?__contentFilePath=/opt/atlassian/pipelines/agent/build/content/blog/white-label-solution-for-food-delivery-app/index.mdx" /* webpackChunkName: "component---src-templates-blog-single-js-content-file-path-content-blog-white-label-solution-for-food-delivery-app-index-mdx" */),
  "component---src-templates-blog-single-js-content-file-path-content-blog-white-label-trusted-taxi-dispatch-software-index-mdx": () => import("./../../../src/templates/blog-single.js?__contentFilePath=/opt/atlassian/pipelines/agent/build/content/blog/white-label-trusted-taxi-dispatch-software/index.mdx" /* webpackChunkName: "component---src-templates-blog-single-js-content-file-path-content-blog-white-label-trusted-taxi-dispatch-software-index-mdx" */),
  "component---src-templates-blog-single-js-content-file-path-content-blog-why-limousine-services-adopt-dispatch-software-index-mdx": () => import("./../../../src/templates/blog-single.js?__contentFilePath=/opt/atlassian/pipelines/agent/build/content/blog/why-limousine-services-adopt-dispatch-software/index.mdx" /* webpackChunkName: "component---src-templates-blog-single-js-content-file-path-content-blog-why-limousine-services-adopt-dispatch-software-index-mdx" */),
  "component---src-templates-blog-single-js-content-file-path-content-blog-why-taxi-business-needs-dispatch-software-index-mdx": () => import("./../../../src/templates/blog-single.js?__contentFilePath=/opt/atlassian/pipelines/agent/build/content/blog/why-taxi-business-needs-dispatch-software/index.mdx" /* webpackChunkName: "component---src-templates-blog-single-js-content-file-path-content-blog-why-taxi-business-needs-dispatch-software-index-mdx" */),
  "component---src-templates-blog-single-js-content-file-path-content-blog-why-you-must-introduce-food-delivery-business-in-your-existing-taxi-business-index-mdx": () => import("./../../../src/templates/blog-single.js?__contentFilePath=/opt/atlassian/pipelines/agent/build/content/blog/why-you-must-introduce-food-delivery-business-in-your-existing-taxi-business/index.mdx" /* webpackChunkName: "component---src-templates-blog-single-js-content-file-path-content-blog-why-you-must-introduce-food-delivery-business-in-your-existing-taxi-business-index-mdx" */),
  "component---src-templates-blog-single-js-content-file-path-content-blog-why-your-ride-hailing-company-must-adopt-digital-meters-index-mdx": () => import("./../../../src/templates/blog-single.js?__contentFilePath=/opt/atlassian/pipelines/agent/build/content/blog/why-your-ride-hailing-company-must-adopt-digital-meters/index.mdx" /* webpackChunkName: "component---src-templates-blog-single-js-content-file-path-content-blog-why-your-ride-hailing-company-must-adopt-digital-meters-index-mdx" */),
  "component---src-templates-blog-single-js-content-file-path-content-blog-working-of-wallet-system-important-features-index-mdx": () => import("./../../../src/templates/blog-single.js?__contentFilePath=/opt/atlassian/pipelines/agent/build/content/blog/working-of-wallet-system-important-features/index.mdx" /* webpackChunkName: "component---src-templates-blog-single-js-content-file-path-content-blog-working-of-wallet-system-important-features-index-mdx" */),
  "component---src-templates-blog-single-js-content-file-path-content-blog-yelowsoft-2021-year-review-index-mdx": () => import("./../../../src/templates/blog-single.js?__contentFilePath=/opt/atlassian/pipelines/agent/build/content/blog/yelowsoft-2021-year-review/index.mdx" /* webpackChunkName: "component---src-templates-blog-single-js-content-file-path-content-blog-yelowsoft-2021-year-review-index-mdx" */),
  "component---src-templates-blog-single-js-content-file-path-content-blog-yelowsoft-assist-cput-to-robust-e-hailing-platform-index-mdx": () => import("./../../../src/templates/blog-single.js?__contentFilePath=/opt/atlassian/pipelines/agent/build/content/blog/yelowsoft-assist-cput-to-robust-e-hailing-platform/index.mdx" /* webpackChunkName: "component---src-templates-blog-single-js-content-file-path-content-blog-yelowsoft-assist-cput-to-robust-e-hailing-platform-index-mdx" */),
  "component---src-templates-blog-single-js-content-file-path-content-blog-yelowsoft-assist-take-me-to-emerge-as-leading-taxi-booking-service-in-saudi-arabia-index-mdx": () => import("./../../../src/templates/blog-single.js?__contentFilePath=/opt/atlassian/pipelines/agent/build/content/blog/yelowsoft-assist-take-me-to-emerge-as-leading-taxi-booking-service-in-saudi-arabia/index.mdx" /* webpackChunkName: "component---src-templates-blog-single-js-content-file-path-content-blog-yelowsoft-assist-take-me-to-emerge-as-leading-taxi-booking-service-in-saudi-arabia-index-mdx" */),
  "component---src-templates-blog-single-js-content-file-path-content-blog-yelowsoft-celebrates-3-years-of-success-index-mdx": () => import("./../../../src/templates/blog-single.js?__contentFilePath=/opt/atlassian/pipelines/agent/build/content/blog/yelowsoft-celebrates-3-years-of-success/index.mdx" /* webpackChunkName: "component---src-templates-blog-single-js-content-file-path-content-blog-yelowsoft-celebrates-3-years-of-success-index-mdx" */),
  "component---src-templates-blog-single-js-content-file-path-content-blog-yelowsoft-delivers-a-super-app-to-mycar-index-mdx": () => import("./../../../src/templates/blog-single.js?__contentFilePath=/opt/atlassian/pipelines/agent/build/content/blog/yelowsoft-delivers-a-super-app-to-mycar/index.mdx" /* webpackChunkName: "component---src-templates-blog-single-js-content-file-path-content-blog-yelowsoft-delivers-a-super-app-to-mycar-index-mdx" */),
  "component---src-templates-blog-single-js-content-file-path-content-blog-yelowsoft-delivers-super-app-to-afghanistan-client-bbr-index-mdx": () => import("./../../../src/templates/blog-single.js?__contentFilePath=/opt/atlassian/pipelines/agent/build/content/blog/yelowsoft-delivers-super-app-to-afghanistan-client-bbr/index.mdx" /* webpackChunkName: "component---src-templates-blog-single-js-content-file-path-content-blog-yelowsoft-delivers-super-app-to-afghanistan-client-bbr-index-mdx" */),
  "component---src-templates-blog-single-js-content-file-path-content-blog-yelowsoft-delivers-super-app-to-mexican-client-click-to-go-index-mdx": () => import("./../../../src/templates/blog-single.js?__contentFilePath=/opt/atlassian/pipelines/agent/build/content/blog/yelowsoft-delivers-super-app-to-mexican-client-click-to-go/index.mdx" /* webpackChunkName: "component---src-templates-blog-single-js-content-file-path-content-blog-yelowsoft-delivers-super-app-to-mexican-client-click-to-go-index-mdx" */),
  "component---src-templates-blog-single-js-content-file-path-content-blog-yelowsoft-evolves-south-african-yo-taxi-into-super-app-index-mdx": () => import("./../../../src/templates/blog-single.js?__contentFilePath=/opt/atlassian/pipelines/agent/build/content/blog/yelowsoft-evolves-south-african-yo-taxi-into-super-app/index.mdx" /* webpackChunkName: "component---src-templates-blog-single-js-content-file-path-content-blog-yelowsoft-evolves-south-african-yo-taxi-into-super-app-index-mdx" */),
  "component---src-templates-blog-single-js-content-file-path-content-blog-yelowsoft-guides-navigo-become-multi-service-provider-in-thailand-index-mdx": () => import("./../../../src/templates/blog-single.js?__contentFilePath=/opt/atlassian/pipelines/agent/build/content/blog/yelowsoft-guides-navigo-become-multi-service-provider-in-thailand/index.mdx" /* webpackChunkName: "component---src-templates-blog-single-js-content-file-path-content-blog-yelowsoft-guides-navigo-become-multi-service-provider-in-thailand-index-mdx" */),
  "component---src-templates-blog-single-js-content-file-path-content-blog-yelowsoft-guides-re-serve-to-become-successful-taxi-company-index-mdx": () => import("./../../../src/templates/blog-single.js?__contentFilePath=/opt/atlassian/pipelines/agent/build/content/blog/yelowsoft-guides-re-serve-to-become-successful-taxi-company/index.mdx" /* webpackChunkName: "component---src-templates-blog-single-js-content-file-path-content-blog-yelowsoft-guides-re-serve-to-become-successful-taxi-company-index-mdx" */),
  "component---src-templates-blog-single-js-content-file-path-content-blog-yelowsoft-helps-bigo-lite-become-popular-ride-hailing-service-costa-rica-index-mdx": () => import("./../../../src/templates/blog-single.js?__contentFilePath=/opt/atlassian/pipelines/agent/build/content/blog/yelowsoft-helps-bigo-lite-become-popular-ride-hailing-service-costa-rica/index.mdx" /* webpackChunkName: "component---src-templates-blog-single-js-content-file-path-content-blog-yelowsoft-helps-bigo-lite-become-popular-ride-hailing-service-costa-rica-index-mdx" */),
  "component---src-templates-blog-single-js-content-file-path-content-blog-yelowsoft-helps-bookmi-revamp-taxi-booking-solution-index-mdx": () => import("./../../../src/templates/blog-single.js?__contentFilePath=/opt/atlassian/pipelines/agent/build/content/blog/yelowsoft-helps-bookmi-revamp-taxi-booking-solution/index.mdx" /* webpackChunkName: "component---src-templates-blog-single-js-content-file-path-content-blog-yelowsoft-helps-bookmi-revamp-taxi-booking-solution-index-mdx" */),
  "component---src-templates-blog-single-js-content-file-path-content-blog-yelowsoft-helps-crabbyride-with-technical-solution-in-nigeria-index-mdx": () => import("./../../../src/templates/blog-single.js?__contentFilePath=/opt/atlassian/pipelines/agent/build/content/blog/yelowsoft-helps-crabbyride-with-technical-solution-in-nigeria/index.mdx" /* webpackChunkName: "component---src-templates-blog-single-js-content-file-path-content-blog-yelowsoft-helps-crabbyride-with-technical-solution-in-nigeria-index-mdx" */),
  "component---src-templates-blog-single-js-content-file-path-content-blog-yelowsoft-helps-ghanian-client-to-become-leading-ride-hailing-service-index-mdx": () => import("./../../../src/templates/blog-single.js?__contentFilePath=/opt/atlassian/pipelines/agent/build/content/blog/yelowsoft-helps-ghanian-client-to-become-leading-ride-hailing-service/index.mdx" /* webpackChunkName: "component---src-templates-blog-single-js-content-file-path-content-blog-yelowsoft-helps-ghanian-client-to-become-leading-ride-hailing-service-index-mdx" */),
  "component---src-templates-blog-single-js-content-file-path-content-blog-yelowsoft-helps-gotta-go-become-successful-taxi-company-bermuda-index-mdx": () => import("./../../../src/templates/blog-single.js?__contentFilePath=/opt/atlassian/pipelines/agent/build/content/blog/yelowsoft-helps-gotta-go-become-successful-taxi-company-bermuda/index.mdx" /* webpackChunkName: "component---src-templates-blog-single-js-content-file-path-content-blog-yelowsoft-helps-gotta-go-become-successful-taxi-company-bermuda-index-mdx" */),
  "component---src-templates-blog-single-js-content-file-path-content-blog-yelowsoft-helps-ibuk-establish-bike-taxi-car-rental-parcel-delivery-services-philippines-index-mdx": () => import("./../../../src/templates/blog-single.js?__contentFilePath=/opt/atlassian/pipelines/agent/build/content/blog/yelowsoft-helps-ibuk-establish-bike-taxi-car-rental-parcel-delivery-services-philippines/index.mdx" /* webpackChunkName: "component---src-templates-blog-single-js-content-file-path-content-blog-yelowsoft-helps-ibuk-establish-bike-taxi-car-rental-parcel-delivery-services-philippines-index-mdx" */),
  "component---src-templates-blog-single-js-content-file-path-content-blog-yelowsoft-helps-sandra-to-provide-best-taxi-booking-service-in-south-africa-index-mdx": () => import("./../../../src/templates/blog-single.js?__contentFilePath=/opt/atlassian/pipelines/agent/build/content/blog/yelowsoft-helps-sandra-to-provide-best-taxi-booking-service-in-south-africa/index.mdx" /* webpackChunkName: "component---src-templates-blog-single-js-content-file-path-content-blog-yelowsoft-helps-sandra-to-provide-best-taxi-booking-service-in-south-africa-index-mdx" */),
  "component---src-templates-blog-single-js-content-file-path-content-blog-yelowsoft-helps-secure-express-to-become-successful-taxi-company-in-south-africa-index-mdx": () => import("./../../../src/templates/blog-single.js?__contentFilePath=/opt/atlassian/pipelines/agent/build/content/blog/yelowsoft-helps-secure-express-to-become-successful-taxi-company-in-south-africa/index.mdx" /* webpackChunkName: "component---src-templates-blog-single-js-content-file-path-content-blog-yelowsoft-helps-secure-express-to-become-successful-taxi-company-in-south-africa-index-mdx" */),
  "component---src-templates-blog-single-js-content-file-path-content-blog-yelowsoft-helps-travee-succeed-in-caribbean-taxi-markets-index-mdx": () => import("./../../../src/templates/blog-single.js?__contentFilePath=/opt/atlassian/pipelines/agent/build/content/blog/yelowsoft-helps-travee-succeed-in-caribbean-taxi-markets/index.mdx" /* webpackChunkName: "component---src-templates-blog-single-js-content-file-path-content-blog-yelowsoft-helps-travee-succeed-in-caribbean-taxi-markets-index-mdx" */),
  "component---src-templates-blog-single-js-content-file-path-content-blog-yelowsoft-introduces-automated-driver-payment-settlement-index-mdx": () => import("./../../../src/templates/blog-single.js?__contentFilePath=/opt/atlassian/pipelines/agent/build/content/blog/yelowsoft-introduces-automated-driver-payment-settlement/index.mdx" /* webpackChunkName: "component---src-templates-blog-single-js-content-file-path-content-blog-yelowsoft-introduces-automated-driver-payment-settlement-index-mdx" */),
  "component---src-templates-blog-single-js-content-file-path-content-blog-yelowsoft-introduces-major-modifications-in-their-pricing-schema-index-mdx": () => import("./../../../src/templates/blog-single.js?__contentFilePath=/opt/atlassian/pipelines/agent/build/content/blog/yelowsoft-introduces-major-modifications-in-their-pricing-schema/index.mdx" /* webpackChunkName: "component---src-templates-blog-single-js-content-file-path-content-blog-yelowsoft-introduces-major-modifications-in-their-pricing-schema-index-mdx" */),
  "component---src-templates-blog-single-js-content-file-path-content-blog-yelowsoft-introduces-major-ui-updates-in-the-admin-panel-index-mdx": () => import("./../../../src/templates/blog-single.js?__contentFilePath=/opt/atlassian/pipelines/agent/build/content/blog/yelowsoft-introduces-major-ui-updates-in-the-admin-panel/index.mdx" /* webpackChunkName: "component---src-templates-blog-single-js-content-file-path-content-blog-yelowsoft-introduces-major-ui-updates-in-the-admin-panel-index-mdx" */),
  "component---src-templates-blog-single-js-content-file-path-content-blog-yelowsoft-introduces-super-app-index-mdx": () => import("./../../../src/templates/blog-single.js?__contentFilePath=/opt/atlassian/pipelines/agent/build/content/blog/yelowsoft-introduces-super-app/index.mdx" /* webpackChunkName: "component---src-templates-blog-single-js-content-file-path-content-blog-yelowsoft-introduces-super-app-index-mdx" */),
  "component---src-templates-blog-single-js-content-file-path-content-blog-yelowsoft-introduces-the-hours-tracking-feature-index-mdx": () => import("./../../../src/templates/blog-single.js?__contentFilePath=/opt/atlassian/pipelines/agent/build/content/blog/yelowsoft-introduces-the-hours-tracking-feature/index.mdx" /* webpackChunkName: "component---src-templates-blog-single-js-content-file-path-content-blog-yelowsoft-introduces-the-hours-tracking-feature-index-mdx" */),
  "component---src-templates-blog-single-js-content-file-path-content-blog-yelowsoft-introduces-web-booking-window-update-index-mdx": () => import("./../../../src/templates/blog-single.js?__contentFilePath=/opt/atlassian/pipelines/agent/build/content/blog/yelowsoft-introduces-web-booking-window-update/index.mdx" /* webpackChunkName: "component---src-templates-blog-single-js-content-file-path-content-blog-yelowsoft-introduces-web-booking-window-update-index-mdx" */),
  "component---src-templates-blog-single-js-content-file-path-content-blog-yelowsoft-product-update-july-index-mdx": () => import("./../../../src/templates/blog-single.js?__contentFilePath=/opt/atlassian/pipelines/agent/build/content/blog/yelowsoft-product-update-july/index.mdx" /* webpackChunkName: "component---src-templates-blog-single-js-content-file-path-content-blog-yelowsoft-product-update-july-index-mdx" */),
  "component---src-templates-blog-single-js-content-file-path-content-blog-yelowsoft-releases-new-version-update-index-mdx": () => import("./../../../src/templates/blog-single.js?__contentFilePath=/opt/atlassian/pipelines/agent/build/content/blog/yelowsoft-releases-new-version-update/index.mdx" /* webpackChunkName: "component---src-templates-blog-single-js-content-file-path-content-blog-yelowsoft-releases-new-version-update-index-mdx" */),
  "component---src-templates-blog-single-js-content-file-path-content-blog-yelowsoft-replaces-socket-io-with-mqtt-index-mdx": () => import("./../../../src/templates/blog-single.js?__contentFilePath=/opt/atlassian/pipelines/agent/build/content/blog/yelowsoft-replaces-socket-io-with-mqtt/index.mdx" /* webpackChunkName: "component---src-templates-blog-single-js-content-file-path-content-blog-yelowsoft-replaces-socket-io-with-mqtt-index-mdx" */),
  "component---src-templates-blog-single-js-content-file-path-content-blog-yelowsoft-steps-to-elevate-customer-experience-index-mdx": () => import("./../../../src/templates/blog-single.js?__contentFilePath=/opt/atlassian/pipelines/agent/build/content/blog/yelowsoft-steps-to-elevate-customer-experience/index.mdx" /* webpackChunkName: "component---src-templates-blog-single-js-content-file-path-content-blog-yelowsoft-steps-to-elevate-customer-experience-index-mdx" */),
  "component---src-templates-blog-single-js-content-file-path-content-blog-yelowsoft-updates-map-view-section-of-its-taxi-solution-index-mdx": () => import("./../../../src/templates/blog-single.js?__contentFilePath=/opt/atlassian/pipelines/agent/build/content/blog/yelowsoft-updates-map-view-section-of-its-taxi-solution/index.mdx" /* webpackChunkName: "component---src-templates-blog-single-js-content-file-path-content-blog-yelowsoft-updates-map-view-section-of-its-taxi-solution-index-mdx" */),
  "component---src-templates-blog-single-js-content-file-path-content-blog-yelowsoft-year-in-review-2020-index-mdx": () => import("./../../../src/templates/blog-single.js?__contentFilePath=/opt/atlassian/pipelines/agent/build/content/blog/yelowsoft-year-in-review-2020/index.mdx" /* webpackChunkName: "component---src-templates-blog-single-js-content-file-path-content-blog-yelowsoft-year-in-review-2020-index-mdx" */),
  "component---src-templates-blog-single-js-content-file-path-content-blog-yelowsoft-yelowtaxi-2023-key-takeaways-index-mdx": () => import("./../../../src/templates/blog-single.js?__contentFilePath=/opt/atlassian/pipelines/agent/build/content/blog/yelowsoft-yelowtaxi-2023-key-takeaways/index.mdx" /* webpackChunkName: "component---src-templates-blog-single-js-content-file-path-content-blog-yelowsoft-yelowtaxi-2023-key-takeaways-index-mdx" */),
  "component---src-templates-blog-single-js-content-file-path-content-blog-yelowtaxi-impact-corporate-transport-budgets-index-mdx": () => import("./../../../src/templates/blog-single.js?__contentFilePath=/opt/atlassian/pipelines/agent/build/content/blog/yelowtaxi-impact-corporate-transport-budgets/index.mdx" /* webpackChunkName: "component---src-templates-blog-single-js-content-file-path-content-blog-yelowtaxi-impact-corporate-transport-budgets-index-mdx" */),
  "component---src-templates-blog-single-js-content-file-path-content-blog-yelowxpress-a-powerful-solution-for-all-your-delivery-needs-index-mdx": () => import("./../../../src/templates/blog-single.js?__contentFilePath=/opt/atlassian/pipelines/agent/build/content/blog/yelowxpress-a-powerful-solution-for-all-your-delivery-needs/index.mdx" /* webpackChunkName: "component---src-templates-blog-single-js-content-file-path-content-blog-yelowxpress-a-powerful-solution-for-all-your-delivery-needs-index-mdx" */),
  "component---src-templates-blog-single-js-content-file-path-content-blog-yelowxpress-an-on-demand-parcel-delivery-solution-by-yelowsoft-index-mdx": () => import("./../../../src/templates/blog-single.js?__contentFilePath=/opt/atlassian/pipelines/agent/build/content/blog/yelowxpress-an-on-demand-parcel-delivery-solution-by-yelowsoft/index.mdx" /* webpackChunkName: "component---src-templates-blog-single-js-content-file-path-content-blog-yelowxpress-an-on-demand-parcel-delivery-solution-by-yelowsoft-index-mdx" */),
  "component---src-templates-blog-single-js-content-file-path-content-blog-yelowxpress-grocery-delivery-headless-technology-index-mdx": () => import("./../../../src/templates/blog-single.js?__contentFilePath=/opt/atlassian/pipelines/agent/build/content/blog/yelowxpress-grocery-delivery-headless-technology/index.mdx" /* webpackChunkName: "component---src-templates-blog-single-js-content-file-path-content-blog-yelowxpress-grocery-delivery-headless-technology-index-mdx" */),
  "component---src-templates-categories-js": () => import("./../../../src/templates/categories.js" /* webpackChunkName: "component---src-templates-categories-js" */),
  "component---src-templates-clone-single-js": () => import("./../../../src/templates/clone-single.js" /* webpackChunkName: "component---src-templates-clone-single-js" */),
  "component---src-templates-compare-single-js": () => import("./../../../src/templates/compare-single.js" /* webpackChunkName: "component---src-templates-compare-single-js" */),
  "component---src-templates-feature-single-js": () => import("./../../../src/templates/feature-single.js" /* webpackChunkName: "component---src-templates-feature-single-js" */),
  "component---src-templates-webinar-single-js": () => import("./../../../src/templates/webinar-single.js" /* webpackChunkName: "component---src-templates-webinar-single-js" */)
}

